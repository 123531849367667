<template>
  <q-page class="col">
    <iframe :src="frameurl" frameborder="0" width="100%" height="600" allowtransparency></iframe>
  </q-page>
</template>
<script>
export default {

  data(){
    return{
      frameurl:""
    }
  },
  mounted(){
              var jwt = require("jsonwebtoken");

    var METABASE_SITE_URL = "https://trackanalytic.ionoxsoftware.in";
    var METABASE_SECRET_KEY =
      "b64dda36cbad1885cf6101d9ea809cb6cffff275903653313ff8991c5eccfa7e";

    var payload = {
      resource: { dashboard: 68 },
      params: {},
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    };
    var token = jwt.sign(payload, METABASE_SECRET_KEY);

    this.frameurl =
      METABASE_SITE_URL +
      "/embed/dashboard/" +
      token +
      "#bordered=true&titled=true";
  }
};
</script>
