import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'


createApp(App).use(Quasar, quasarUserOptions).use(router).mount('#app')


// Vue.use(TawkMessengerVue, {
//     propertyId : '620bbb6a1ffac05b1d7a00dd',
//     widgetId : '1frut86ms'
// });


const axios = require("axios").default;

// Add a response interceptor
axios.defaults.withCredentials = true;

console.log("uid",localStorage.getItem('uid'));

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    config.withCredentials = true;
    config.headers.post["token"] = localStorage.getItem('uid');
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.log("reponsecode ",error.response.status);
  if(error.response.status == 401)
  {
     console.log("401 ",error.response.data); 
     //window.location.href = "/";
    //vm.goLogin(); //==> diable login for testing
  }
  return Promise.reject(error);
});



