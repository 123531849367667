<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Froms</div>

    <!-- main card -->
    <div class="q-pa-md q-gutter-md" style="width:80%;margin:auto">
     
     <formview></formview>

    </div>
  </q-page>
</template>


<script>
import formview from "./FormViewer2.vue"

export default {
  name: "FormTest",
  components: {
    formview
  },
  data() {
    return {
      step: 1,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["Standard", "Express"],
      shape: "line",
    };
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>