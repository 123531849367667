<script>
import { h } from "vue";
import { QBtn } from "quasar";
import * as form1 from "./testdata/Form1.js";

export default {
  data() {
    return {
      msg: "hello",
    };
  },
  render() {
    //console.log(form1.form1);

    var formschema = form1.form1;
    //console.log(formschema);

    var fom = [];
    //render all the pages
    for (var i = 0; i < formschema.pages.length; i++) {
      var page = formschema.pages[i];
      console.log(page);
      for (var j = 0; j < page.elements.length; j++) {
        console.log("-->", page.elements[j].type);
        fom.push(
          h(QBtn, {
            label: "Standard",
            color: "green",
            icon: "check",
            ref: "abc",
          })
        );
      }
    }

    return fom;
    // return h(QBtn, {
    //   label: "Standard",
    //   color: "green",
    //   icon: "check",
    //   ref:"abc"
    // });
  },
  mounted() {
    console.log(this.$refs.abc);
    window.abc = this.$refs.abc;
  },
};
</script>
