<template>
  <!-- Connfig Dialog -->
  <q-dialog v-model="value">
    <q-card style="width: 700px; max-width: 80vw;">
      <q-toolbar>
        <q-avatar icon="local_shipping" font-size="30px"></q-avatar>

        <q-toolbar-title>
          <span class="text-weight-bold">Order</span> Details
        </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>

      <!-- card settings -->
      <q-card-section>
        <!-- Main Div -->
        <div class="row" style="width:100%">
          <!-- title and description -->
          <div class="col-12">
            <span class="text-h6">
              Order Number:
              <span class="text-subtitle1">{{order.order_number}}</span>
            </span>
            <br />
            <span class="text-h6">
              Tracking Number:
              <span class="text-subtitle1">{{order.tracking_number}}</span>
            </span><br>
            <span class="text-h6">
              Status:
              <span class="text-subtitle1">{{order.order_status}}</span>
            </span>
          </div>
          <div class="col-12">
            <div class="row">
              <!-- pickup details -->
              <q-card class="q-ma-md" flat bordered>
                <q-card-section>
                  <div class="text-h6">Pickup Details</div>
                  <div class="text-subtitle2">Name: <span class="text-subtitle1">{{order.pickup.name}}</span></div>
                  <div class="text-subtitle2">Addressline1: <span class="text-subtitle1">{{order.pickup.address_line1}}</span> </div>
                  <div class="text-subtitle2">Addressline2: <span class="text-subtitle1">{{order.pickup.address_line2}}</span></div>
                  <div class="text-subtitle2">landmark: <span class="text-subtitle1">{{order.pickup.landmark}}</span></div>
                  <div class="text-subtitle2">state: <span class="text-subtitle1">{{order.pickup.state}}</span></div>
                  <div class="text-subtitle2">city:<span class="text-subtitle1">{{order.pickup.city}}</span></div>
                  <div class="text-subtitle2">pincode:<span class="text-subtitle1">{{order.pickup.pincode}}</span></div>
                  <div class="text-subtitle2">mobile:<span class="text-subtitle1">{{order.pickup.mobile}}</span></div>
                </q-card-section>
              </q-card>
              <!-- pickup details -->
              <!-- delivery details -->
              <q-card class="q-ma-md" flat bordered>
                <q-card-section>
                  <div class="text-h6">Delivery Details</div>
                  <div class="text-subtitle2">Name: <span class="text-subtitle1">{{order.delivery.name}}</span></div>
                  <div class="text-subtitle2">Addressline1: <span class="text-subtitle1">{{order.delivery.address_line1}}</span> </div>
                  <div class="text-subtitle2">Addressline2: <span class="text-subtitle1">{{order.delivery.address_line2}}</span></div>
                  <div class="text-subtitle2">landmark: <span class="text-subtitle1">{{order.delivery.landmark}}</span></div>
                  <div class="text-subtitle2">state: <span class="text-subtitle1">{{order.delivery.state}}</span></div>
                  <div class="text-subtitle2">city:<span class="text-subtitle1">{{order.delivery.city}}</span></div>
                  <div class="text-subtitle2">pincode:<span class="text-subtitle1">{{order.delivery.pincode}}</span></div>
                  <div class="text-subtitle2">mobile:<span class="text-subtitle1">{{order.delivery.mobile}}</span></div>
                </q-card-section>
              </q-card>
              <!-- delivery details -->
            </div>
          </div>
          <div class="col-12">
            <span class="text-subtitle1">Order Items:</span>
            <br />
            <div class="row">
              <!-- item details -->
              <q-card v-for="item in order.items" :key="item.id" class="q-ma-md" flat bordered>
                <q-card-section>
                  <div class="text-subtitle2">Name: {{item.item_name}}</div>
                  <div class="text-subtitle2">Quantity: {{item.item_qty}}</div>
                  <div class="text-subtitle2">Item total value:  {{item.item_total_value}}/-</div>
                </q-card-section>
              </q-card>
              <!-- item details -->
            </div>
          </div>
          <!-- package details -->
          <div class="col-12">
            <span class="text-subtitle1">Package details:</span>
            <br />
            <div class="row">
              <!-- parcel details -->
              <q-card class="q-ma-md" flat bordered>
                <q-card-section>
                  <div class="text-h6">Package details</div>
                  <div class="text-subtitle2">Weight: {{order.real_weight}}gm</div>
                  <div class="text-subtitle2">length: {{order.shipment_length}}</div>
                  <div class="text-subtitle2">breadth: {{order.shipment_breadth}}</div>
                  <div class="text-subtitle2">height: {{order.shipment_height}}</div>
                  <div class="text-subtitle2">Volumetric Weight: {{order.volumetric_weight}}</div>
                </q-card-section>
              </q-card>
              <!-- pickup details -->
              <!-- shipment details -->
              <q-card class="q-ma-md" flat bordered>
                <q-card-section>
                  <div class="text-h6">Shipment details</div>
                  <div class="text-subtitle2">Courier name: {{order.courier_name}}</div>
                  <div class="text-subtitle2">Shippping charge: {{order.shipping_charge}}</div>
                </q-card-section>
              </q-card>
              <!-- shipment details -->
            </div>
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: "Order View Dialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
};
</script>