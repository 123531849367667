<template>
  <!-- Connfig Dialog -->
 
    <q-card style="width: 700px; max-width: 80vw;">
      <q-toolbar>
        <q-avatar icon="local_shipping" font-size="30px"></q-avatar>

        <q-toolbar-title>
          <span class="text-weight-bold">Order</span> Status
        </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>

      <!-- card settings -->
      <q-card-section>
        <!-- timeline -->
        <q-timeline color="secondary">
          <q-timeline-entry class="text-h6">Current Status: {{order.order_status}}</q-timeline-entry>

          <template v-for="(item,index) in order.order_status_history.history" :key="index">
            <q-timeline-entry :title="item.status" :subtitle="item.time">
              <div>
                <span class="text-subtitle1">
                  <b>location:</b>
                  {{item.location}}
                </span>
                <br />
                <span class="text-subtitle1">
                  <b>message:</b>
                  {{item.message}}
                </span>
              </div>
            </q-timeline-entry>
          </template>
        </q-timeline>

        <!-- timeline end -->
      </q-card-section>

      <q-inner-loading :showing="innerLoading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </q-card>
 
</template>

<script>
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;

export default {
  name: "Order status View Dialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: function () {
        return {
          data:{}
        };
      },
    },
  },
  mounted(){
    this.api_trackParcel();
  },
  data() {
    return {
      innerLoading: false,
    };
  },
  methods: {
    api_trackParcel() {

      this.innerLoading = true;
      axios
        .post(ULRrepo.TRACK_PARCEL+"?odr="+this.order.order_number, {})
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data=response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          console.warn("");
          this.innerLoading = false;
        });
    },
  },
};
</script>