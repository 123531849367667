<template>
  <q-page class="col">
    <welcome></welcome>

<div  class="row q-gutter-md justify-center">
  <!-- Wallet Balance -->
    <q-card class="carddesign" flat bordered>
      <div class="row">
        <div style="width:150px;height:150px">
          <q-img style="    margin-top: 15%;"  class="col" src="@/assets/walleta.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">Wallet Balance</div>
          <div class="text-h4 q-ma-md text-italic	">₹ {{insight.balance}}/-</div>
        </div>
      </div>
    </q-card>
   <!-- Open Shipment  -->
    <q-card class="carddesign" flat bordered>
      <div class="row">
        <div   style="width:150px;height:150px;">
          <q-img style="margin-top: 15%;" class="col" src="@/assets/delivery.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">In Transist</div>
          <div class="text-h4 q-ma-md text-italic	">{{insight.total_processing}}</div>
        </div>
      </div>
    </q-card>
    <!-- Delivered -->
        <q-card class="carddesign" flat bordered>
      <div class="row">
        <div   style="width:150px;height:150px;">
          <q-img style=" margin-top: 15%;" class="col" src="@/assets/cash.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">COD Orders</div>
          <div class="text-h4 q-ma-md text-italic	" >{{insight.total_cod}}</div>
        </div>
      </div>
    </q-card>
      <!-- Delivered -->
        <q-card class="carddesign" flat bordered>
      <div class="row">
        <div   style="width:150px;height:150px;">
          <q-img style=" margin-top: 15%;" class="col" src="@/assets/prepaid.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">Preapid Orders</div>
          <div class="text-h4 q-ma-md text-italic	">{{insight.total_prepaid}}</div>
        </div>
      </div>
    </q-card> 
    <!-- Delivered -->
     <q-card class="carddesign" flat bordered>
      <div class="row">
        <div   style="width:150px;height:150px;padding: 10px;">
          <q-img style="" class="col" src="@/assets/Courier-app.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">Delivered</div>
          <div class="text-h4 q-ma-md text-italic">{{insight.total_delivered}}</div>
        </div>
      </div>
    </q-card>
    <!-- Delete -->
    <q-card class="carddesign" flat bordered>
      <div class="row">
        <div   style="width:150px;height:150px;padding: 10px;">
          <q-img style=" margin-top: 15%;" class="col" src="@/assets/delete_photo.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">Cancelled Orders</div>
          <div class="text-h4 q-ma-md text-italic">{{insight.total_cancel}}</div>
        </div>
      </div>
    </q-card>
    <!-- Total Orders -->
    <q-card class="carddesign" flat bordered>
      <div class="row">
        <div   style="width:150px;height:150px;padding: 10px;">
          <q-img style=" margin-top: 15%;" class="col" src="@/assets/packaging.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">Total Orders</div>
          <div class="text-h4 q-ma-md text-italic">{{insight.total_order}}</div>
        </div>
      </div>
    </q-card>
     <!-- cod remitted -->
    <q-card class="carddesign" flat bordered>
      <div class="row">
        <div   style="width:150px;height:150px;padding: 10px;">
          <q-img style=" margin-top: 15%;"  class="col" src="@/assets/box.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">Draft Orders</div>
          <div class="text-h4 q-ma-md text-italic">{{insight.total_draft}}</div>
        </div>
      </div>
    </q-card>
    <!-- cod remitted -->
    <q-card class="carddesign" flat bordered>
      <div class="row">
        <div   style="width:150px;height:150px;padding: 10px;">
          <q-img style=" margin-top: 15%;" class="col" src="@/assets/money1.gif" />
        </div>
        <div class="col q-ma-md text-center">
          <div class="text-h6 text-italic	">COD Remitted</div>
          <div class="text-h4 q-ma-md text-italic">Rs. {{insight.cod_remitted}}/-</div>
        </div>
      </div>
    </q-card>


    <!-- inner loading -->
       <q-inner-loading :showing="innerLoading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>

</div>

    <randomchart v-if="false"></randomchart>

    <!-- <q-card dark bordered class="bg-grey-9 my-card">
      <q-card-section>
        <div class="text-h6">Recent Activity</div>
      </q-card-section>
      <q-separator dark inset />
      <q-card-section>
     
        

     </q-card-section>
    </q-card>-->
  </q-page>
</template>


<script>
import welcome from "@/components/banner/welcome.vue";
import randomchart from "@/components/charts_widgets/RandomChart.vue";

import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;

export default {
  name: "HelloWorld",
  components: {
    welcome,
    randomchart,
  },
  mounted(){
    this.api_loadInsight();
  },
  data(){
    return{
      insight:{},
      innerLoading:false
    }
  },
  methods:{

    api_loadInsight(){
    
     this.innerLoading = true;
      axios.post(ULRrepo.USER_INSIGHT, {})
      .then(response => {
       if (response.status == 200) {
        console.warn(response.data);
        this.insight = response.data.data;
      }
      })
      .catch(error => {
      console.error(error);
      })
      .then(() => {
      console.warn('');
      this.innerLoading=false;
      });

    }


  }
};
</script>

<style lang="css" scoped>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.carddesign{
  max-width:350px;
  border-radius: 10px;
}

</style>