<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: "MainApp",

  components: {
  },

  data() {
    return {
    };
  }
};
</script>

<style></style>
