<template>
  <q-page class="q-pb-md">
    <div class="row q-ma-lg justify-left q-pa-md q-mb-md" style="width:100%;margin:auto">
      <div class="inputs text-h6 col-12">Company Info</div>

      <div class="inputs col-4">
        <span class="q-mb-md">Company Name</span>
        <q-input v-model="formData.company_name" outlined dense />
      </div>
      <div v-if="false" class="inputs col-4">
        <span class="q-mb-md">Company Code</span>
        <q-input v-model="formData.company_code" readonly outlined dense />
      </div>
      <div class="inputs col-8">
        <span class="q-mb-md">Website URL</span>
        <q-input v-model="formData.website" outlined dense />
      </div>
      <div class="inputs col-4">
        <span class="q-mb-md">Email</span>

        <q-input v-model="formData.email" readonly outlined dense />
      </div>
      <div class="inputs col-4">
        <span class="q-mb-md">Contact No.</span>

        <q-input v-model="formData.mobile" outlined dense />
      </div>
      <div class="inputs col-4">
        <span class="q-mb-md">Pan Number</span>

        <q-input v-model="formData.pan_number" outlined dense />
      </div>
      <div class="inputs col-4">
        <span class="q-mb-md">GST Number</span>

        <q-input v-model="formData.gst_number" outlined dense />
      </div>
      <div class="inputs col-8">
        <span class="q-mb-md">Company Address</span>

        <q-input v-model="formData.address" outlined dense type="textarea" />
      </div>

      <div class="inputs">
        <span class="q-mb-md col-4">City</span>

        <q-input v-model="formData.city" outlined dense />
      </div>
      <div class="inputs">
        <span class="q-mb-md col-4">State</span>

        <q-input v-model="formData.state" outlined dense />
      </div>
      <div class="inputs">
        <span class="q-mb-md col-4">Pincode</span>

        <q-input v-model="formData.pincode" outlined dense />
      </div>

      <div class="inputs text-h6 col-12">Bank Details</div>
      <div class="inputs col-2">
        <span class="q-mb-md">Account Name</span>
        <q-input v-model="formData.bank_details.account_name" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">Bank Name</span>
        <q-input v-model="formData.bank_details.bank_name" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">Account Number</span>
        <q-input v-model="formData.bank_details.account_number" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">IFSC code</span>
        <q-input v-model="formData.bank_details.ifsc" outlined dense />
      </div>

      <div class="inputs text-h6 col-12">Print Label Logo</div>
      <div class="inputs col-12">
        <i>Please set image size to Width:260px, Height:60px <br> please refresh page after upload</i>
        <div class="flex q-mt-md">
          <q-btn
            label="Upload Logo"
            color="primary"
            dense
            @click="showUploader=true"
            v-if="showUploader==false"
            />
          <q-uploader v-if="showUploader" style="max-width: 300px" :url="'https://files.shippingwala.in/upload.php/'+formData.id+'shipment.png'" label="upload your logo" accept=".png" field-name="fileToUpload" @rejected="onRejected" auto-upload max-files="1" />
          <img class="q-ml-md"  :src="'https://files.shippingwala.in/uploads/'+formData.id+'shipment.png?'+Math.random()" style="width:400px;height:100px;" @error="$event.target.src=require('@/assets/logo_banner.png')" />
        </div>
      </div>

      <q-separator style="width: 100%;" />
      <div class="col-12 q-mt-md text-right">
        <q-btn @click="api_savecompany()" color="primary" no-caps label="Save Changes"/>
      </div>
    </div>
    <q-inner-loading :showing="loading">
      <q-spinner-gears size="50px" color="primary" />
    </q-inner-loading>
  </q-page>
</template>


<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;

export default {
  mixins: [globalmix],
  name: "UserSetting",
  components: {},
  mounted() {
    this.api_loadcompany();
  },
  data() {
    return {
      showUploader:false,
      formData: {
        bank_details: {},
      },
    };
  },
  methods: {
    api_loadcompany() {
      this.loading = true;
      axios
        .post(ULRrepo.USER_COMPANY_INFO, "")
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.formData = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },

    api_savecompany() {
      this.loading = true;
      axios
        .post(ULRrepo.USER_COMPANY_INFO_SAVE, this.formData)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            //this.formData = response.data.data;
            //this.showSuccess("Company Info Saved Successfully");
            this.api_loadcompany();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError("Error saving");
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>