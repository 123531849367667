<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Froms</div>

    <!-- main card -->
    <div class="q-pa-md q-gutter-md" style="width:80%;margin:auto">
      <!-- Radio button -->
      <q-card style="width:100%">
        <q-item>
          <q-item-section>
            <q-item-label>FormType</q-item-label>
            <q-item-label caption>Form info</q-item-label>
          </q-item-section>
        </q-item>

        <q-card-section class="q-pt-xs">
          <q-input outlined label="Form Title" />
          <q-input outlined class="q-pt-xs" dense label="Description" />
        </q-card-section>

        <q-card-section class="q-pt-xs">
          <div class="q-gutter-xs row">
            <q-radio class="col-12" v-model="shape" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" val="line" label="Line" />
            <q-radio class="col-12" v-model="shape" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" val="rectangle" label="Rectangle" />
            <q-radio class="col-12" v-model="shape" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" val="ellipse" label="Ellipse" />
            <q-radio class="col-12" v-model="shape" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" val="polygon" label="Polygon" />
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions>
          <q-btn flat round icon="event" />
          <q-btn flat>7:30PM</q-btn>
          <q-btn flat color="primary">Reserve</q-btn>
        </q-card-actions>
      </q-card>

      <!-- Card button -->

      <q-card style="width:100%">
        <q-item>
          <q-item-section>
            <q-item-label>FormType</q-item-label>
            <q-item-label caption>Form info</q-item-label>
          </q-item-section>
        </q-item>

        <q-card-section class="q-pt-xs">
          <q-input outlined label="Form Title" />
          <q-input outlined class="q-pt-xs" dense label="Description" />
        </q-card-section>

        <q-card-section class="q-pt-xs">
          <div class="q-gutter-xs row">
            <q-checkbox class="col-12" v-model="shape" label="Teal" color="teal" />
            <q-checkbox class="col-12" v-model="shape" label="Orange" color="orange" />
            <q-checkbox class="col-12" v-model="shape" label="Red" color="red" />
            <q-checkbox class="col-12" v-model="shape" label="Cyan" color="cyan" />
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions>
          <q-btn flat round icon="event" />
          <q-btn flat>7:30PM</q-btn>
          <q-btn flat color="primary">Reserve</q-btn>
        </q-card-actions>
      </q-card>

        <!-- Select -->

         <q-card style="width:100%">
        <q-item>
          <q-item-section>
            <q-item-label>Select</q-item-label>
            <q-item-label caption>Form info</q-item-label>
          </q-item-section>
        </q-item>

        <q-card-section class="q-pt-xs">
          <q-input outlined label="Form Title" />
          <q-input outlined class="q-pt-xs" dense label="Description" />
        </q-card-section>

        <q-card-section class="q-pt-xs">
          <div class="q-gutter-xs row">
            <q-checkbox class="col-12" v-model="shape" label="Teal" color="teal" />
            <q-checkbox class="col-12" v-model="shape" label="Orange" color="orange" />
            <q-checkbox class="col-12" v-model="shape" label="Red" color="red" />
            <q-checkbox class="col-12" v-model="shape" label="Cyan" color="cyan" />
          </div>
        </q-card-section>

        <q-separator />

        <q-card-actions>
          <q-btn flat round icon="event" />
          <q-btn flat>7:30PM</q-btn>
          <q-btn flat color="primary">Reserve</q-btn>
        </q-card-actions>
      </q-card>


    </div>
  </q-page>
</template>


<script>
export default {
  name: "Form",
  components: {},
  data() {
    return {
      step: 1,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["Standard", "Express"],
      shape: "line",
    };
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>