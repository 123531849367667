<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Shipments</div>

    <div class="row items-start" style="width:90%;margin:auto">
      <!-- sencond inline bar -->
      <div class="row q-ma-sm">
        <!-- <q-btn color="primary" label="add new order" class="q-mr-xs q-mt-xs" @click="$router.push('/neworders')" /> -->
        <!-- <q-btn color="primary" label="download excel template" class="q-mr-xs q-mt-xs" @click="api_downloadTemplateFile()" />
        <q-btn color="primary" label="upload excel file" class="q-mr-xs q-mt-xs" @click="api_uploadFile()" />-->
        <!-- <q-btn color="primary" :label="'bulk print label = '+selectedrders.length" class="q-mr-xs q-mt-xs" @click="bulkPrintLabel()" /> -->
      </div>
    </div>

    <!-- cod remmitance grid -->
    <div style="width:90%;margin:auto">
      <q-card flat bordered>
        <q-card-section>
          <div class="text-h6 q-mt-sm q-mb-xs">COD Remmitance</div>
          <div class="text-subtitle1">Company Name: {{codRemmitance.name}}</div>
          <div class="text-subtitle1">Total Orders: {{codRemmitance.odrList.length}}</div>
          <div class="text-subtitle1">Total Amount:{{codRemmitance.amount}}</div>

          <q-input filled class="q-mr-xs q-mt-xs" v-model="codRemmitance.description" label="remittance description" dense style="width: 100%;"></q-input>
          <q-btn color="primary" label="generate cod remmitane" class="q-mr-xs q-mt-xs" @click="generateCodRemmitance()" />
        </q-card-section>

        <q-card-actions>
          <q-btn label="view Orders" flat dense :icon="showRemittance ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" @click="showRemittance = !showRemittance" />
        </q-card-actions>

        <q-slide-transition>
          <div v-show="showRemittance">
            <q-separator />
            <q-card-section class="text-subitle2">
              <div class="q-gutter-xs">
                <!-- chip list -->
                <template v-for="(item,index) in codRemmitance.odrList" :key="index">
                  <q-chip color="teal" text-color="white">{{item}}</q-chip>
                </template>
                <!-- chip list end -->
              </div>
            </q-card-section>
          </div>
        </q-slide-transition>
      </q-card>
    </div>
    <!-- End COD remmitance -->

    <div class="q-pa-md row items-start" style="width:90%;margin:auto">
      <q-input filled class="q-mr-xs q-mt-xs" v-model="search.startDate" label="Start date" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.startDate !== ''" name="close" @click.stop="search.startDate = ''" class="cursor-pointer" />
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy cover ref="qDateProxy" transition-show="scale" transition-hide="scale">
              <q-date v-model="search.startDate">
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn label="OK" color="primary" flat v-close-popup />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>

      <q-input filled class="q-mr-xs q-mt-xs" v-model="search.endDate" label="End date" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.endDate !== ''" name="close" @click.stop="search.endDate = ''" class="cursor-pointer" />
          <q-icon name="event" class="cursor-pointer">
            <q-popup-proxy cover ref="qendDateProxy" transition-show="scale" transition-hide="scale">
              <q-date v-model="search.endDate" @input="() => $refs.qendDateProxy.hide()">
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn label="OK" color="primary" flat v-close-popup />
                </div>
              </q-date>
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>

      <q-select stack-label class="q-mr-xs q-mt-xs" filled v-model="search.company_id" option-label="company_name" option-value="id" :options="companyNameList" emit-value label="company" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.company_id !== ''" name="close" @click.stop="search.company_id = ''" class="cursor-pointer" />
        </template>
      </q-select>

      <q-select stack-label class="q-mr-xs q-mt-xs" filled v-model="search.orderStatus" option-label="order_status" option-value="order_status" :options="orderStatusList" emit-value label="status" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.orderStatus !== ''" name="close" @click.stop="search.orderStatus = ''" class="cursor-pointer" />
        </template>
      </q-select>

      <q-select stack-label class="q-mr-xs q-mt-xs" filled v-model="search.payment_method" option-label="paymentmethod" option-value="code" :options="paymentMethod" emit-value label="payment method" dense style="width: 150px;">
        <template v-slot:append>
          <q-icon size="xs" v-if="search.payment_method !== ''" name="close" @click.stop="search.payment_method = ''" class="cursor-pointer" />
        </template>
      </q-select>

      <q-btn color="primary" icon="search" label="search" class="q-mr-xs q-mt-xs" @click="btn_serarch()" />
      <!-- <q-btn color="primary" icon="fas fa-download" label="download data" class="q-mr-xs q-mt-xs" @click="api_downloadData()" /> -->
    </div>

    <!-- main card -->
    <div class="card q-pa-md scroll-x" style="width:90%;margin:auto">
      <div class="row q-ma-sm">
        <q-input filled class="q-mr-xs q-mt-xs" v-model="search.tracking" label="search tracking number or order number" dense style="width: 300px;">
          <template v-slot:append>
            <q-icon size="xs" v-if="search.tracking !== ''" name="closex" @click.stop="search.tracking = ''" class="cursor-pointer" />
          </template>
        </q-input>
        <q-btn color="primary" label="Search" class="q-mr-xs q-mt-xs" @click="btn_serarch()" />

        <!--
              <q-input filled class="q-mr-xs q-mt-xs" v-model="search.mobilenumber" label="search customer mobile number" dense style="width: 300px;">
                <template v-slot:append>
                  <q-icon size="xs" v-if="search.mobilenumber !== ''" name="closexm" @click.stop="search.mobilenumber = ''" class="cursor-pointer" />
                </template>
        </q-input>-->
      </div>
      <!-- <q-table :data="data" :columns="columns" row-key="name" :rows-per-page-options="[1]">
          <template v-slot:body-cell-action="props">
            <q-td :props="props">
              <q-btn size="xs" round color="primary" icon="fas fa-eye" class="q-mr-xs" @click="tableViewButton(props)" />
              <q-btn size="xs" round color="primary" icon="edit" class="q-mr-xs" @click="tableEditButton(props)" />
              <q-btn size="xs" round color="secondary" icon="delete" @click="tableDeleteButton(props)" />
            </q-td>
          </template>
      </q-table>-->

      <!-- Table -->
      <table class="styled-table">
        <thead>
          <tr>
            <th></th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Company_name</th>
            <th>Order Number</th>
            <th>Tracking Number</th>
            <th>User Name</th>
            <th>Payment Method</th>
            <th>COD Value</th>
            <th>COD Remmitance</th>
            <th>Courier name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>
              <q-btn v-if="item.cod_remittance_status == 'pending'" @click="codRemitanceSelect(item)" round :color="codRemmitanceSelected(item)? 'green' : 'yellow'" text-color="black" icon="currency_exchange" />
            </td>
            <td>{{new Date(item.created_at).toLocaleString()}}</td>
            <td>{{new Date(item.updated_at).toLocaleString()}}</td>
            <td>{{item.company.company_name}}</td>
            <td>{{item.order_number}}</td>
            <td>{{item.tracking_number || ""}}</td>
            <td>{{(item.user != undefined ? item.user.first_name+" "+item.user.last_name : "")}}</td>
            <td>{{item.payment_method || ""}}</td>
            <td>{{item.cod_amount || ""}}</td>
            <td>{{item.cod_remittance_status || ""}}</td>
            <td>{{item.courier_name || ""}}</td>
            <td>{{item.order_status || ""}}</td>
            <td>
              <q-btn-dropdown size="sm" color="primary" label="Actions">
                <q-list dense>
                  <q-item clickable v-close-popup @click="bulkPrintLabel(item)">
                    <q-item-section avatar>
                      <q-avatar icon="print" color="primary" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Print Label</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="viewOrder(item)">
                    <q-item-section avatar>
                      <q-avatar icon="visibility" color="primary" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>View Order Details</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="api_cancelOrder(item.order_number)">
                    <q-item-section avatar>
                      <q-avatar icon="delete_forever" color="red" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Cancel Order</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="editOrder(item.order_number)">
                    <q-item-section avatar>
                      <q-avatar icon="edit" color="primary" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Edit Order</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="editOrder(item.order_number)">
                    <q-item-section avatar>
                      <q-avatar icon="local_shipping" color="green" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Ship Order</q-item-label>
                    </q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="viewStatusHistory(item)">
                    <q-item-section avatar>
                      <q-avatar icon="format_list_numbered" color="green" text-color="white" />
                    </q-item-section>
                    <q-item-section>
                      <q-item-label>Status history</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="10" class="text-center">
              <q-btn-group rounded>
                <q-btn no-caps v-if="pageno>0" @click="previousPage()" color="green" rounded icon="chevron_left" label="Previous" />
                <q-btn no-caps v-if="!lastpage" @click="nextPage()" color="green" rounded icon-right="navigate_next" label="Next" />
              </q-btn-group>
              Total Records: {{totalRecords}}
            </td>
          </tr>
        </tfoot>
      </table>
      <!-- Table End -->
    </div>

    <orderViewDialig v-model="viewdialog" :order="viewdialogData"></orderViewDialig>

    <q-dialog v-model="orderStatusHistoryDialog">
      <orderhistory :order="orderStatusHistoryDialogData"></orderhistory>
    </q-dialog>

    <loader v-model="loading"></loader>
  </q-page>
</template>


<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";
import orderViewDialig from "@/views/orders/OrderViewDialog.vue";
import orderhistory from "@/views/orders/OrderStatusHistoryDialog.vue";

export default {
  mixins: [globalmix],
  name: "OrdersListadmin",
  components: {
    loader,
    orderViewDialig,
    orderhistory,
  },
  mounted() {
    this.pageno = 0;
    this.api_loadOrderStatus();
    this.api_loadorders();
    this.api_loadCompanyDetails();
  },
  data() {
    return {
      showRemittance: false,
      orderStatusHistoryDialog: false,
      orderStatusHistoryDialogData: {},
      viewdialog: false,
      viewdialogData: {},
      lastpage: false,
      pageno: 0,
      step: 1,
      totalRecords: 0,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["cod", "prepaid"],
      search: {
        startDate: "",
        endDate: "",
        tracking: "",
        mobilenumber: "",
        orderStatus: "",
        payment_method: "",
        company_id: "",
      },
      selectedrders: [],
      data: [],
      orderStatusList: [],
      companyNameList: [],
      codRemmitance: {
        company: {},
        description: "",
        name: "",
        odrList: [],
        amount: 0,
      },
    };
  },
  methods: {
    nextPage() {
      this.pageno++;
      this.api_loadorders();
    },
    previousPage() {
      if (this.pageno > 0) {
        this.pageno--;
        this.api_loadorders();
      }
    },
    api_loadorders() {
      var queryParam = {
        orders_v3: {
          page: this.pageno,
          _desc: "id",
          _filters: {
            // created_at: "> " + `'${this.search.startDate} 00:00'`,
            // "#created_at": "< " + `'${this.search.endDate} 23:59'`,
          },
        },
      };
      //set the filters for the tracking number
      if (this.search.tracking != "" && this.search.tracking != undefined) {
        queryParam.orders_v3._filtersor = {};
        queryParam.orders_v3._filtersor.order_number = `LIKE '%${this.search.tracking}%'`;
        queryParam.orders_v3._filtersor.tracking_number = `LIKE '%${this.search.tracking}%'`;
      }
      //set the filters
      if (this.search.orderStatus != "") {
        queryParam.orders_v3._filters.order_status =
          "='" + this.search.orderStatus + "'";
      }

      if (this.search.payment_method != "") {
        queryParam.orders_v3._filters.payment_method =
          "='" + this.search.payment_method + "'";
      } 
      
      //companyid
      if (this.search.company_id != "") {
        queryParam.orders_v3._filters.company_id =
          "='" + this.search.company_id + "'";
      }

      //time filter
      if (this.search.startDate != "" && this.search.endDate != "") {
        queryParam.orders_v3._filters.created_at =
          "> " + `'${this.search.startDate} 00:00'`;
        queryParam.orders_v3._filters["#created_at"] =
          "< " + `'${this.search.endDate} 23:59'`;
      }

      this.loading = true;
      axios
        .post(ULRrepo.QUERY, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data = response.data.data;
            this.totalRecords = response.data.totalRecords;

            //calucate last page
            var totalRecords = response.data.totalRecords;
            var totalPages = Math.round(totalRecords / 25);
            if (this.pageno >= totalPages) {
              this.lastpage = true;
            } else {
              this.lastpage = false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
    bulkPrintLabel(prop) {
      window.toprint = [];
      if (prop != undefined) {
        window.toprint = [prop];
      } else {
        window.toprint = this.selectedrders;
      }
      //this.goToPageWithParams("shiplabelprinter",{lables:JSON.stringify(this.selectedrders)})
      this.$router.push("shiplabelprinter");
    },
    goToPageWithParams(routeName, paramsObj) {
      console.log(paramsObj);
      this.$router.push({
        name: routeName,
        params: paramsObj,
      });
    },
    api_loadOrderStatus() {
      this.loading = true;
      axios
        .post(ULRrepo.GENERIC_ORDER_STATUS, {})
        .then((response) => {
          if (response.status == 200) {
            this.orderStatusList = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
          //this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_loadCompanyDetails() {
      var queryParam = {
        company_detail: {
          page: this.pageno,
          _desc: "id",
          _filters: {},
          limit: 10000,
        },
      };

      this.loading = true;
      axios
        .post(ULRrepo.QUERY, queryParam)
        .then((response) => {
          if (response.status == 200) {
            this.companyNameList = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
          //this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    btn_serarch() {
      this.pageno = 0;
      this.api_loadorders();
    },
    editOrder(number) {
      this.$router.push({
        name: "neworders",
        query: { odr: number },
      });
    },
    viewOrder(orderdata) {
      this.viewdialogData = orderdata;
      this.viewdialog = true;
    },
    viewStatusHistory(orderdata) {
      this.orderStatusHistoryDialogData = orderdata;
      this.orderStatusHistoryDialog = true;
    },
    codRemitanceSelect(order) {
      console.log("--->", order.order_number);
      if (this.codRemmitance.odrList.length <= 0) {
        this.codRemmitance.name = order.company.company_name;
        this.codRemmitance.company = order.company;
        this.codRemmitance.odrList.push(order.order_number);
        this.codRemmitance.amount += order.cod_amount;
      }
      //this logic store the complete node of order
      // else if(this.codRemmitance.odrList[0].company.id ==order.company_id){
      //     var index = this.codRemmitance.odrList.findIndex(x=>x.order_number==order.order_number);
      //     if(index>-1){
      //         this.codRemmitance.odrList.splice(index,1);
      //        this.codRemmitance.amount-=order.cod_amount;
      //     }else{
      //         this.codRemmitance.odrList.push(order);
      //         this.codRemmitance.amount+=order.cod_amount;
      //     }
      // }
      //bleow logic only sotr the order number in the arrary
      else if (this.codRemmitance.company.id == order.company_id) {
        var index = this.codRemmitance.odrList.findIndex(
          (x) => x == order.order_number
        );
        if (index > -1) {
          this.codRemmitance.odrList.splice(index, 1);
          this.codRemmitance.amount -= order.cod_amount;
        } else {
          this.codRemmitance.odrList.push(order.order_number);
          this.codRemmitance.amount += order.cod_amount;
        }
      } else {
        this.showError("Please select same company");
      }
    },
    codRemmitanceSelected(order) {
      var index = this.codRemmitance.odrList.findIndex(
        (x) => x == order.order_number
      );
      if (index > -1) {
        return true;
      } else {
        return false;
      }
    },
    generateCodRemmitance() {
      this.loading = true;
      //=======================
      axios
        .post(ULRrepo.COD_MAN_GEN, this.codRemmitance)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);

            //reset the model
            this.codRemmitance = {
              company: {},
              description: "",
              name: "",
              odrList: [],
              amount: 0,
            };
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_cancelOrder(orderNumber) {
      this.loading = true;
      axios
        .post(ULRrepo.ORDER_CANCEL + "?order=" + orderNumber, {})
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.doc-card-title {
  margin-left: -24px;
  padding: 2px 10px 2px 24px;
  background: #e0e0e0;
  color: #616161;
  position: relative;
  border-radius: 3px 5px 5px 0;
}

.doc-card-title:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border: 0 solid transparent;
  border-top-color: #bebebe;
  border-width: 9px 0 0 11px;
}
</style>