import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

//import the splitted routes
import OrdersRoutes from './OrdersRoutes'

//========================
//=== Dashboard
import Dash from '../views/dashboard/Dash'


//=======================
//==== Orders
import NewOrder from '../views/orders/NewOrder.vue';
import OrderList from '../views/orders/OrdersList.vue';
import ShipLabelPrinter from '../views/orders/ShipLabelPrinter.vue';

//====== ADMIN
//------- user managenment
import UserManagement from '../views/admin/users/UserManagement.vue'
import OrderAdmin from '../views/admin/orders/OrdersListadmin.vue'

//-------- wallet
import WalletMaster from '../views/admin/wallet/WalletMaster.vue'
import WalletMasterLedger from '../views/admin/wallet/WalletMasterLedger.vue'

//======= ADMIN END

//=======================
//==== Wallet
import Wallet from '../views/wallet/Wallet.vue'

//=====================
//=== COD Remmitance
import CodRemmitance from '../views/codremittance/CodRemittance.vue'
import CodRemmitanceApprove from '../views/admin/codremmitance/remitanceApprove.vue'


//===================
//=== Accounts
import Accounts from '../views/accounts/Accounts.vue'

//===================
//=== Branding
import Branding from '../views/branding/Branding.vue'
//---
import SmsBranding from '../views/branding/sms/SmsBranding.vue'


//===================
//=== FORMS
import Form from '../module/forms/Form.vue'
import FormViewer from '../module/forms/FormViewer.vue'
import FormElements from '../module/forms/FormElements.vue'
import FormEditor from '../module/forms/FormEditor.vue'
import FormManager from '../module/forms/FormManager.vue'
import FormTest from '../module/forms/FormTest.vue'
import Formgoogle from '../module/forms/FormViewergoogle.vue'

//====================
//==== Login Register forget
import Login from '../views/login/Login.vue'
import Register from '../views/register/Register.vue'
import Forgot from '../views/forgot/Forgot.vue'


//====================
//=== GPS tracking
import Tracking from '../module/tracking/Tracking.vue';


//=====================
//==== Flows

import Flows from '../module/flows/Flows.vue';
import Flow2 from '../module/flows/Flow2.vue';

//=====================
//===== company
import Company from '../views/admin/company/Company.vue'
import CompanyNew from '../views/admin/company/CompanyNew.vue'

//====================
//== pricing
import Pricing from '../views/admin/pricing/Pricing.vue'

//====================
//== category
import Category from '../views/admin/category/Category.vue'

//====================
//== shipping cost calculator
import shippingCostCalculator from '../views/shippingCost/ShippingCostCalulator.vue'


//====================
// user profile
import UserSetting from '../views/user_settings/UserSetting.vue'
import MyCompanyInfo from '../views/user_settings/MyCompanyInfo.vue'
import MyUserInfo from '../views/user_settings/MyUserInfo.vue'

//====================
//== account
import MyOnlineTxn from '../views/accounts/MyOnlineTxn.vue'
import MyRemmitance from '../views/accounts/MyRemmitance.vue'

//========================
//=== warehouse
import Mywarehouse from '../views/warehouse/Mywarehouse.vue'
import MydropLocation from '../views/dropLocation/MydropLocation.vue'

//========================
//==== 
import Help from '../views/user/help/Help.vue';

//========================
//==== Developer
import Developer from '../views/user/developer/Developer.vue';

//=======================
//===== Analytics
import Analytics from '../views/admin/analytics/Analytics.vue';


const routes = [
  {
    path: "/shiplabelprinter",
    name: "shiplabelprinter",
    component: ShipLabelPrinter
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    children: [
      ...OrdersRoutes,
      {
        path: '/dash',
        name: 'dash',
        component: Dash
      }, 
        {
        path: '/analytics',
        name: 'analytics',
        component: Analytics
      },
      {
        path: '/neworders',
        name: 'neworders',
        component: NewOrder
      },
      {
        path: '/orderlist',
        name: 'orderlist',
        component: OrderList
      },
      {
        path: '/walletmaster',
        name: 'walletmaster',
        component: WalletMaster
      },
      {
        path: '/help',
        name: 'help',
        component: Help
      },
      {
        path: '/developer',
        name: 'developer',
        component: Developer
      },
      {
        path: '/walletmasterledger',
        name: 'walletmasterledger',
        component: WalletMasterLedger
      },
      {
        path: '/acodremmitanceapprove',
        name: 'acodremmitanceapprove',
        component: CodRemmitanceApprove
      },
      {
        path: '/codremmitance',
        name: 'codremmitance',
        component: CodRemmitance
      },
      {
        path: '/umanage',
        name: 'umanage',
        component: UserManagement
      }, {
        path: '/orderadmin',
        name: 'orderadmin',
        component: OrderAdmin
      },
      {
        path: '/accounts',
        name: 'accounts',
        component: Accounts,
        children: [
          {
            path: '/codremmitance',
            name: 'codremmitance',
            component: CodRemmitance
          },
          {
            path: '/myonlinetxn',
            name: 'myonlinetxn',
            component: MyOnlineTxn
          },
          {
            path: '/myremmitance',
            name: 'myremmitance',
            component: MyRemmitance
          }
        ]
      },
      {
        path: '/branding',
        name: 'branding',
        component: Branding,
        children: [
          {
            path: '/smsbranding',
            name: 'smsbranding',
            component: SmsBranding
          }
        ]
      },
      {
        path: '/usersetting',
        name: 'usersetting',
        component: UserSetting,
        children: [
          {
            path: '/mcompanyinfo',
            name: 'mcompanyinfo',
            component: MyCompanyInfo
          },
          {
            path: '/muserinfo',
            name: 'muserinfo',
            component: MyUserInfo
          }
        ]
      },

      {
        path: '/wallet',
        name: 'wallet',
        component: Wallet
      },
      {
        path: '/mywarehouse',
        name: 'mywarehouse',
        component: Mywarehouse
      }, {
        path: '/mydroplocation',
        name: 'mydroplocation',
        component: MydropLocation
      },
      {
        path: '/form',
        name: 'form',
        component: Form
      },
      {
        path: '/formtest',
        name: 'formtest',
        component: FormTest
      },
      {
        path: '/company',
        name: 'company',
        component: Company
      },
      {
        path: '/shipcost',
        name: 'shipcost',
        component: shippingCostCalculator
      },
      {
        path: '/companynew',
        name: 'companynew',
        component: CompanyNew
      },
      {
        path: '/pricing',
        name: 'pricing',
        component: Pricing
      }, {
        path: '/category',
        name: 'category',
        component: Category
      },
    ]
  },
  {
    path: '/formviewer',
    name: 'formviewer',
    component: FormViewer
  },
  {
    path: '/formeditor',
    name: 'formeditor',
    component: FormEditor
  },
  {
    path: '/formmanager',
    name: 'formmanager',
    component: FormManager
  },
  {
    path: '/formelements',
    name: 'formelements',
    component: FormElements
  },
  {
    path: '/formgoogle',
    name: 'formgoogle',
    component: Formgoogle
  },
  

  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: Forgot
  },
  {
    path: '/tracking',
    name: 'tracking',
    component: Tracking
  },
  {
    path: '/flows',
    name: 'flows',
    component: Flows
  },
  {
    path: '/flow2',
    name: 'flow2',
    component: Flow2
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

console.log("router loaded");

export default router
