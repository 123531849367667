<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-page-container>
      <q-page class="flex flex-center">
        <div class="row" style="width:100%">
          <!-- 1st div -->
          <div class="col-12 col-md-6 flex flex-center">
            <!-- form div -->
            <div class="q-pa-md">
              <div class="q-mb-lg">
                <img alt="Quasar logo" v-if="!$q.screen.gt.sm" src="https://picsum.photos/600" style="width: 100%; height: 200px" />
              </div>
              <div class="text-h3 q-mt-md text-weight-bold">Welcome back! ✨</div>
              <div class="col-12 q-mt-md">
                <span class="q-mb-md text-subtitle1 text-grey">
                  Email id
                  <span class="text-red">*</span>
                </span>
                <q-input v-model="formData.email" outlined dense />
              </div>
              <div class="col-12 q-mt-md">
                <span class="q-mb-md text-subtitle1 text-grey">
                  Password
                  <span class="text-red">*</span>
                </span>
                <q-input type="password" v-model="formData.password" outlined dense />
              </div>
              <div class="row q-mt-md text-subtitle1 flex flex-center">
                <div class="col-6"><a href="/#/forgot">Forgot Password?</a></div>
                <div class="col-6 text-right">
                  <q-btn  @click="api_auth()" color="primary" no-caps label="Sign in" />
                </div>
              </div>
              <div class="q-mt-md text-subtitle2" @click="$router.push('/register')">
                <a href="/#/register">Don’t you have an account? Sign Up</a>
              </div>
            </div>
            <!-- form div end-->
          </div>
          <!-- Image div -->
          <div class="col-6 flex flex-center" v-if="$q.screen.gt.sm">
            <img alt="Quasar logo" src="https://picsum.photos/600" style="width: 100%; height: 100vh" />
          </div>
        </div>
        <loader v-model="loading"></loader>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "Login",
  components: {
    loader,
  },
  mounted() {
    console.log("$q.screen.gt.sm ==> ", this.$q.screen.gt.sm);
  },
  data() {
    return {
      formData: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    api_auth() {
      this.loading = true;
      axios
        .post(ULRrepo.AUTH, this.formData,{withCredentials: true})
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
            //save the token in the localstorage
            localStorage.setItem("uid", response.data.token);
            localStorage.setItem("user", JSON.stringify(response.data.usr));
            localStorage.setItem("comp",JSON.stringify(response.data.comp));
            localStorage.setItem("comp_id",response.data.comp.id);
            
            //check if user has provided his first and last name
            if(response.data.usr.first_name =="" ||  response.data.usr.last_name == ""  )
            {
              this.showInfo("Hey please let us know your first and last name");
              this.$router.push("/muserinfo");
            }else{
              this.$router.push("/dash");
            }

          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .maincard {
  }

  .title {
    font-size: 1.4rem;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  overflow-y: scroll;
  background: rgb(252, 249, 249);
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(8, 8, 8);
  border-radius: 10px;
}

/* The circle on the bottom of the device */
/* .smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
} */

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}
</style>