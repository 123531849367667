<template>
  <q-page class="q-pb-md">
    <!-- <div class="text-h4 text-weight-bold q-ma-lg">COD Remittance</div> -->
    <div class="text-h4 row text-weight-bold q-ma-lg q-gutter-md">
     
       <!-- Wallet Balance -->
      <q-card class="carddesign" flat bordered>
        <div class="row">
          <div style="width:90px;height:90px">
            <q-img  class="col" src="@/assets/remitance.gif" />
          </div>
          <div class="col q-ma-sm text-center">
            <div class="text-subtitle1 text-italic">Remitted Till Date</div>
            <div class="text-subtitle1  text-italic">₹ 500/-</div>
          </div>
        </div>
      </q-card>
       <!-- Wallet Balance -->
      <q-card class="carddesign" flat bordered>
        <div class="row">
          <div style="width:90px;height:90px">
            <q-img style="margin-top: 15%;" class="col q-ml-sm" src="@/assets/approve.gif" />
          </div>
          <div class="col q-ma-sm text-center">
            <div class="text-subtitle1 text-italic">Total Remittance Due</div>
            <div class="text-subtitle1  text-italic">₹ 500/-</div>
          </div>
        </div>
      </q-card>
     
   
  
    </div>
    <!-- main card -->
    <div class="card q-pa-md" style="width:80%;margin:auto">
     
    </div>
  </q-page>
</template>


<script>
export default {
  name: "CodRemittance",
  components: {},
  data() {
    return {
      step: 1,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["Standard", "Express"],
    };
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.carddesign {
  max-width: 500px;
  border-radius: 10px;
}
</style>