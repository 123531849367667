<template>
  <q-page class="flex flex-center bg-white">
      <div>
    <img alt="Quasar logo" src="@/assets/turtle.gif" >
      </div>
    <div class="text-h6">
        We are continously working on providing you with better support.
        <br>
        Please contact us <a target="_blank" href="https://shippingwala.in/index.html#findUs">From Here</a>

    </div>
  </q-page>
</template>

<style>
</style>

<script>
export default {
  name: 'Help'
}
</script>
