<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-header elevated class="bg-white text-grey-8 q-py-xs" height-hint="58">
      <q-toolbar>
        <!-- <q-btn
          flat
          dense
          round
          @click="toggleLeftDrawer"
          aria-label="Menu"
          icon="menu"
        />-->

        <q-btn flat no-caps no-wrap class="q-ml-xs" v-if="$q.screen.gt.xs">
          <q-icon :name="fabYoutube" color="red" size="28px" />
          <q-toolbar-title shrink class="text-weight-bold">FORM EDITOR</q-toolbar-title>
        </q-btn>

        <q-space />

        <q-space />

        <div class="q-gutter-sm row items-center no-wrap">
          <q-btn round dense flat color="grey-8" icon="video_call" v-if="$q.screen.gt.sm">
            <q-tooltip>Create a video or post</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="apps" v-if="$q.screen.gt.sm">
            <q-tooltip>Apps</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="message" v-if="$q.screen.gt.sm">
            <q-tooltip>Messages</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="notifications">
            <q-badge color="red" text-color="white" floating>2</q-badge>
            <q-tooltip>Notifications</q-tooltip>
          </q-btn>
          <q-btn round flat>
            <q-avatar size="26px">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
            </q-avatar>
            <q-tooltip>Account</q-tooltip>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <q-page class="q-pb-md q-ma-md">
        <div style="width:90%;margin:auto" class="row justify-center">
          <!-- Left pane -->
          <div class="col-6 card q-mr-md q-pa-md bg-indigo-1">
            <div>
              <q-btn-dropdown color="primary" label="Add Element">
                <q-list>
                  <q-item clickable v-close-popup @click="onItemClick">
                    <q-item-section>
                      <q-item-label>Photos</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item clickable v-close-popup @click="onItemClick">
                    <q-item-section>
                      <q-item-label>Videos</q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item clickable v-close-popup @click="onItemClick">
                    <q-item-section>
                      <q-item-label>Articles</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>

            <!-- <q-card class="q-ma-md">
                      <q-item>
                        <q-item-section avatar>
                          <q-btn round dense flat color="grey-8" icon="settings" v-if="$q.screen.gt.sm">
                            <q-tooltip>Create a video or post</q-tooltip>
                          </q-btn>
                        </q-item-section>

                        <q-item-section>
                          <q-item-label>{{element.title}}</q-item-label>
                          <q-item-label caption>{{element.type}}</q-item-label>
                        </q-item-section>
                      </q-item>
            </q-card>-->
            
            <!-- <div v-if="false" :id="element.title">
              <egeneric :schema="element"></egeneric>
            </div> -->

            <!-- Components lists -->
            <div class="q-pa-md">
              <!-- Title  -->
              <q-input outlined v-model="form.title" label="Title" />
              <q-input type="textarea" class="q-mt-xs" outlined v-model="form.description" label="Description" />
              <div class="card q-pa-xs q-mt-xs bg-indigo-2">
                <draggable :options="{forceFallback: true}" v-model="form.feilds" item-key="id">
                  <template #item="{element}">
                    <div  :id="element.title">
                      <egeneric :schema="element"></egeneric>
                    </div>
                  </template>
                </draggable>
                <!-- Draggable end -->
              </div>
            </div>
          </div>
          <!-- Right Pane -->
          <div class="col-5 card q-pa-md bg-indigo-1">
            <div class="smartphone">
              <formviewer :schema="form"></formviewer>
            </div>
          </div>
        </div>

        <!-- Connfig Dialog -->
        <q-dialog v-model="toolbar">
          <q-card style="width: 700px; max-width: 80vw;">
            <q-toolbar>
              <q-avatar icon="settings" font-size="30px"></q-avatar>

              <q-toolbar-title>
                <span class="text-weight-bold">Text</span> Settings
              </q-toolbar-title>

              <q-btn flat round dense icon="close" v-close-popup />
            </q-toolbar>

            <!-- card settings -->
            <q-card-section>
              <!-- Main Div -->
              <div class="row" style="width:100%">
                <!-- title and description -->
                <div class="col-12">
                  <q-input outlined v-model="form.title" label="Title" />
                </div>
                <div class="col-12">
                  <q-input type="textarea" class="q-mt-xs" outlined v-model="form.description" label="Description" />
                </div>

                <!--- toggle setting -->
                <div>
                  <q-toggle v-model="value" label="Required" />
                  <q-toggle v-model="value" color="green" label="Accept Other" />
                </div>

                <!-- Options -->
                <div class="col-12 q-gutter-xs">
                  <div>Add Options, Press enter when done</div>
                  <q-select outlined v-model="modelAddUnique" use-input use-chips multiple hide-dropdown-icon input-debounce="0" new-value-mode="add-unique" />
                </div>
              </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
              <q-btn flat>Save</q-btn>
            </q-card-actions>
          </q-card>
        </q-dialog>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script>
// import vselect from './components/viewer/Vselect.vue';
// import vradio from './components/viewer/Vradio.vue';
import egeneric from "./components/editor/Egeneric.vue";
// import vtext from './components/viewer/Vtext.vue';
// import vtextarea from './components/viewer/Vtextarea.vue';
// import vslider from './components/viewer/Vslider.vue';
// import vradiogrid from './components/viewer/Vradiogrid.vue';
// import vtitle from './components/viewer/Vtitle.vue';

import formviewer from "./FormViewer.vue";
import draggable from "vuedraggable";


import * as form1 from "./testdata/Form1";

export default {
  name: "FormEditor",
  components: {
    // vselect,
    // vradio,
    egeneric,
    // vtext,
    // vtextarea,
    // vslider,
    // vradiogrid,
    // vtitle
    draggable,
    formviewer,
  },
  data() {
    return {
      result: {},
      value: "",
      modelAddUnique: [],
      drag: true,
      toolbar: false,
      form:form1.form1,
      formx: {
        title: "Course evaluation",
        description:
          "Please submit feedback regarding the course you have just completed, including feedback on course structure, content, and instructor.",
        submitButtonText: "Submit the form",
        feilds: [
          {
            title: "yii",
            description: "my dec",
            type: "select",
            key: "select",
            options: ["abble", "booyy", "caat"],
            required: true,
            value: "",
          },
          {
            title: "yii",
            description: "my dec",
            type: "text",
            key: "text",
            required: true,
            value: "",
          },
          {
            title: "yii",
            description: "my dec",
            type: "textarea",
            key: "textarea",
            required: true,
            value: "",
          },
          {
            title: "yii",
            description: "my dec",
            type: "slider",
            key: "slider",
            required: true,
            value: "10",
          },
          {
            title: "yii",
            description: "my dec",
            type: "radio",
            key: "radio",
            required: true,
            options: ["abble", "booyy", "caat"],
            value: "",
          },
          {
            title: "yii",
            description: "my dec",
            type: "check",
            key: "check",
            required: true,
            options: ["abble", "booyy", "caat"],
            value: [],
          },
          {
            title: "yii",
            description: "my dec",
            type: "radiogrid",
            key: "table",
            required: true,
            value: "",
          },
        ],
      },
    };
  },
  methods: {
    validate() {
      console.log("validating..");
      //console.log(this.$refs.comp[0].hasError());

      for (var i = 0; i < this.$refs.comp.length; i++) {
        if (this.$refs.comp[i].hasError != undefined) {
          console.log("<====", this.$refs.comp[i].hasError());
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .maincard {
  }

  .title {
    font-size: 1.4rem;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  overflow-y: scroll;
  background: rgb(252, 249, 249);
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(8, 8, 8);
  border-radius: 10px;
}

/* The circle on the bottom of the device */
/* .smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
} */

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}
</style>