import rules from "../../rules.js";


export default {
    props: {
        schema: Object,
        result: Object,
    },
    created() {
        this.initialResultValue();
    },
    data(){
        return {
         showError:false
        }
    },
    methods: {

        /**
         * set the invial value of the result schema
         */
        initialResultValue() {
            if (this.result[this.schema.key] == undefined) {
                if (this.schema.value != undefined) {
                    console.log("set the initial value");
                    this.result[this.schema.key] = this.schema.value;
                } else {
                    this.result[this.schema.key] = [];
                }
            }
        },
        hasError() {
            console.log("checking error",this.schema.key,"==>",this.result[this.schema.key],"==>",rules.required(this.result[this.schema.key]));
            this.showError =  !rules.required(this.result[this.schema.key]);
            return this.showError;
        },
        
    }
};