<!-- eslint-disable vue/no-mutating-props -->

<template>
 
      <q-card  :class="showError ? 'bg-red-4':''">
        <q-item>
          <q-item-section>
               <q-item-label>{{schema.title}} <span class="text-red">{{schema.required ? '*' : ''}}</span></q-item-label>
            <q-item-label caption>{{schema.description}}</q-item-label>
          </q-item-section>
        </q-item>
        <q-card-section class="q-pt-xs">
          <q-input v-model="result[schema.key]" outlined />
        </q-card-section>
      </q-card>


</template>

<script>
import vcomponent from  './vComponents.js'

export default {
    mixins: [vcomponent],

  name: "Vtext",
  props: {
     schema:Object
   },
  components: {},
  data() {
    return {
      feildValue:""
    };
  },
  methods:{
   
  }
};
</script>
