<!-- eslint-disable vue/no-mutating-props -->

<template>
  <q-card class="q-ma-md">
    <q-item>
      <q-item-section avatar>
        <q-btn round dense flat color="grey-8" icon="settings" @click="settingDialog=true" >
          <q-tooltip>open settings</q-tooltip>
        </q-btn>
      </q-item-section>

      <q-item-section>
        <q-item-label>{{schema.title}}</q-item-label>
        <q-item-label caption>{{schema.type}}</q-item-label>
      </q-item-section>
    </q-item>
  </q-card>

  <!-- Dialog -->
  <q-dialog v-model="settingDialog">
    <q-card style="width: 700px; max-width: 80vw;">
      <q-toolbar>
        <q-avatar icon="settings" font-size="30px"></q-avatar>

        <q-toolbar-title>
          <span class="text-weight-bold">{{schema.type}}</span> Settings
        </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>

      <!-- card settings -->
      <q-card-section>
        <!-- Main Div -->
        <div class="row" style="width:100%">
          <!-- title and description -->
          <div class="col-12">
            <q-input outlined v-model="schema.title" label="Title" />
          </div>
          <div class="col-12">
            <q-input type="textarea" class="q-mt-xs" outlined v-model="schema.description" label="Description" />
          </div>

          <!--- toggle setting -->
          <div>
            <q-toggle v-model="schema.required" label="Required" />
            <!-- <q-toggle v-model="schema.required" color="green" label="Accept Other" /> -->
          </div>

          <!-- Options -->
          <div v-if="showOptions(schema)"  class="col-12 q-gutter-xs">
            <div>Add Options, Press enter when done</div>
            <q-select outlined v-model="schema.options" use-input use-chips multiple hide-dropdown-icon input-debounce="0" new-value-mode="add-unique" />
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <!-- <q-card-actions align="right">
        <q-btn flat>Save</q-btn>
      </q-card-actions>-->
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  // mixins: [vcomponent],
  name: "Egeneric",
  props: {
    schema: Object,
  },
  created() {
    // this.initialResultValue();
  },
  components: {},
  data() {
    return {
      feildValue: [],
      shape: "line",
      settingDialog: false,
    };
  },
  methods: {
    showOptions(element){
      let text = "check radio select";
      return text.includes(element.type);
    }
  },
};
</script>
