<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Wallet Ledger {{data.length > 0 ? data[0].company.company_name : ''}}</div>

    <!-- main card -->
    <div class="card q-pa-md" style="width:80%;margin:auto">
      <div style="width:100%; overflow-x: scroll;" class="q-mt-md">
        <div class="row">
          <q-input class="q-ma-md" style="width:150px" v-model="formData.amount" outlined dense placeholder="Enter amount" />
          <q-input class="q-ma-md" style="width:250px" v-model="formData.description" outlined dense placeholder="Description" />
          <q-btn unelevated dense style="width:150px" @click="api_walletTxn('debit')" class="q-ma-md" color="red" label="Debit" no-caps />
          <q-btn unelevated dense style="width:150px" @click="api_walletTxn('credit')" class="q-ma-md" color="green" label="Credit" no-caps />
        </div>
        <!-- Table -->
        <table class="styled-table">
          <thead>
            <tr>
              <th>tnx At</th>
              <th>transaction_number</th>
              <th>order_number</th>
              <th>description</th>
              <th>transaction_type</th>
              <th>amount</th>
              <th>balance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.id">
              <td>{{new Date(item.created_at).toLocaleString()}}</td>
              <td>{{item.transaction_number}}</td>
              <td>{{item.order_number || ""}}</td>
              <td>{{item.description || "" }}</td>
              <td>{{item.transaction_type || ""}}</td>
              <td>{{item.amount || ""}}</td>
              <td>{{item.balance || ""}}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="10" class="text-center">
                <q-btn-group rounded>
                  <q-btn no-caps v-if="pageno>0" @click="previousPage()" color="green" rounded icon="chevron_left" label="Previous" />
                  <q-btn no-caps v-if="!lastpage" @click="nextPage()" color="green" rounded icon-right="navigate_next" label="Next" />
                </q-btn-group>
              </td>
            </tr>
          </tfoot>
        </table>
        <!-- Table End -->
      </div>
    </div>
    <loader v-model="loading"></loader>
  </q-page>
</template>

<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "WalletMasterLedger",
  components: {
    loader,
  },
  mounted() {
    this.api_loadData();
  },
  data() {
    return {
      lastpage: false,
      pageno: 0,
      searchText: "",
      data: [],
      formData: {
        amount: 0,
        description: "",
        transaction_type: "",
        company_id: this.$route.query.id,
      },
    };
  },
  methods: {
    nextPage() {
      this.pageno++;
      this.api_loadData();
    },
    previousPage() {
      if (this.pageno > 0) {
        this.pageno--;
        this.api_loadData();
      }
    },
    api_loadData() {
      var queryParam = {
        wallet_ledger: {
          page: this.pageno,
          _desc: "id",
          _filters: {
            company_id: ` = ${this.$route.query.id}`,
          },
        },
      };
      this.loading = true;
      axios
        .post(ULRrepo.QUERY, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data = response.data.data;

            //calucate last page
            var totalRecords = response.data.totalRecords;
            var totalPages = Math.round(totalRecords / 25);
            if (this.pageno >= totalPages) {
              this.lastpage = true;
            } else {
              this.lastpage = false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
    api_walletTxn(type) {
      if (type == "debit") {
        this.formData.transaction_type = "debit";
      }
      if (type == "credit") {
        this.formData.transaction_type = "credit";
      }
      this.loading = true;
      axios
        .post(ULRrepo.USER_PERFROM_TXN, this.formData)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
            //reset form data
            this.formData = {
              amount: 0,
              description: "",
              transaction_type: "",
              company_id: this.$route.query.id,
            };
            this.api_loadData();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>