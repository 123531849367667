<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Branding</div>

    <!-- main card -->
    <div class="card q-pa-md" style="width:80%;margin:auto">
    
     <q-tabs
        v-model="tab"
        inline-label
        class="bg-blue text-white shadow-2"
         align="justify"
      >
        <q-route-tab to="/smsbranding" name="codrem" icon="money" label="Custom SMS" />
        <q-route-tab to="/buses" name="mails" icon="fitness_center" label="Custom Tracking Page" />
        <q-route-tab to="/buses" name="mails" icon="settings_input_antenna" label="Custom Label" />
       
      </q-tabs>
      
     <router-view></router-view>

    </div>
  </q-page>
</template>


<script>
export default {
  name: "Branding",
  components: {},
  data() {
    return {
      step: 1,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["Standard", "Express"],
    };
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>