<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-header elevated class="bg-white text-grey-8 q-py-xs" height-hint="58">
      <q-toolbar>
        <!-- <q-btn
          flat
          dense
          round
          @click="toggleLeftDrawer"
          aria-label="Menu"
          icon="menu"
        />-->

        <q-btn flat no-caps no-wrap class="q-ml-xs" v-if="$q.screen.gt.xs">
          <q-icon :name="fabYoutube" color="red" size="28px" />
          <q-toolbar-title shrink class="text-weight-bold">FORM EDITOR</q-toolbar-title>
        </q-btn>

        <q-space />

        <q-space />

        <div class="q-gutter-sm row items-center no-wrap">
          <q-btn round dense flat color="grey-8" icon="video_call" v-if="$q.screen.gt.sm">
            <q-tooltip>Create a video or post</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="apps" v-if="$q.screen.gt.sm">
            <q-tooltip>Apps</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="message" v-if="$q.screen.gt.sm">
            <q-tooltip>Messages</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="notifications">
            <q-badge color="red" text-color="white" floating>2</q-badge>
            <q-tooltip>Notifications</q-tooltip>
          </q-btn>
          <q-btn round flat>
            <q-avatar size="26px">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
            </q-avatar>
            <q-tooltip>Account</q-tooltip>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <q-page class="q-pb-md q-ma-md">
        <div style="position: relative;width: 100%;min-height: 75vh;" id="mapid3"></div>

        <!-- Users Dialog -->
        <q-dialog v-model="toolbar">
          <q-card style="width: 450px; max-width: 80vw;">
            <q-toolbar>
              <q-avatar icon="settings" font-size="30px"></q-avatar>

              <q-toolbar-title>
                <span class="text-weight-bold">Devices</span> List
              </q-toolbar-title>

              <q-btn flat round dense icon="close" v-close-popup />
       
            </q-toolbar>

            <!-- card settings -->
            <q-card-section class="q-pl-none q-pr-none">
              <!-- Main Div -->
              <div class="row" style="width:100%">
                <!-- title and description -->
                <div class="col-12">
                  <q-input v-if="false" outlined dense label="Search devices" />
                </div>
                <div class="col-12">
                  <q-list class="rounded-borders">
                    <q-item  class="bg-red" v-ripple>
                      <q-item-section avatar>
                        <q-avatar>
                          <img src="https://cdn.quasar.dev/img/avatar2.jpg" />
                        </q-avatar>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label lines="1">Brunch this weekend?</q-item-label>
                        <q-item-label caption lines="2">
                          <span class="text-weight-bold">Last sync: </span>
                          12:55:77
                        </q-item-label>
                      </q-item-section>

                 
                       <q-item-section avatar>
                          <q-btn flat round dense icon="delete_forever" v-close-popup />                                   
                      </q-item-section>


                      <!-- <q-item-section side top>1 min ago</q-item-section> -->
                    </q-item>

                    <q-separator inset="item" />

                    <q-item clickable v-ripple>
                      <q-item-section avatar>
                        <q-avatar>
                          <img src="https://cdn.quasar.dev/img/avatar4.jpg" />
                        </q-avatar>
                      </q-item-section>

                      <q-item-section>
                        <q-item-label lines="1">Linear Project</q-item-label>
                        <q-item-label caption lines="2">
                          <span class="text-weight-bold">John</span>
                          -- Can we schedule a call for tomorrow?
                        </q-item-label>
                      </q-item-section>

                      <q-item-section side top>1 min ago</q-item-section>
                    </q-item>
                  </q-list>
                </div>
              </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
              <q-btn flat>Save</q-btn>
            </q-card-actions>
          </q-card>
        </q-dialog>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "Tracking",
  components: {},
  mounted() {
    this.setupLeaflet();
  },
  data() {
    return {
      result: {},
      value: "",
      modelAddUnique: [],
      drag: true,
      toolbar: true,
      mymap: {},
    };
  },
  methods: {
    setupLeaflet() {
      this.mymap = L.map("mapid3").setView([22.9940772, 88.361723], 13);

      L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
        maxZoom: 100,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }).addTo(this.mymap);

      //create pin
      var redIcon = L.icon({
        iconUrl: require("@/assets/markers/pinred.png"),
        shadowUrl: "",
        iconSize: [50, 50], // size of the icon
        shadowSize: [50, 64], // size of the shadow
        iconAnchor: [25, 25], // point of the icon which will correspond to marker's location
        shadowAnchor: [4, 62], // the same for the shadow
        popupAnchor: [0, 0], // point from which the popup should open relative to the iconAnchor
      });

      //Add Marker
      L.marker([51.5, -0.09], { icon: redIcon })
        .addTo(this.mymap)
        .bindPopup("A pretty CSS3 popup.<br> Easily customizable.")
        .openPopup();
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .maincard {
  }

  .title {
    font-size: 1.4rem;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  overflow-y: scroll;
  background: rgb(252, 249, 249);
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(8, 8, 8);
  border-radius: 10px;
}

/* The circle on the bottom of the device */
/* .smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
} */

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}
</style>