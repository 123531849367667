/* eslint-disable vue/no-unused-vars */



export default {
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        confimDialog(msg, callback) {
            this.$q.dialog({
              title: 'Confirm',
              message: msg,
              cancel: true,
              persistent: true
            }).onOk(() => {
              // console.log('>>>> OK')
              callback();
            }).onOk(() => {
              // console.log('>>>> second OK catcher')
            }).onCancel(() => {
              // console.log('>>>> Cancel')
            }).onDismiss(() => {
              // console.log('I am triggered on both OK and Cancel')
            })
      
          },
        showSuccess(msg) {
            this.$q.notify(
                {
                    group: false,
                    progress: true,
                    position: "top",
                    type: "positive",
                    message: msg,
                },
                3000
            );
        },
        showError(msg) {
            this.$q.notify(
                {
                    group: false,
                    progress: true,
                    position: "top",
                    type: "negative",
                    message: msg,
                },
                3000
            );
        }, 
        showInfo(msg) {
            this.$q.notify(
                {
                    group: false,
                    progress: true,
                    position: "top",
                    type: "info",
                    message: msg,
                },
                3000
            );
        },

    },
};