<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-header elevated class="bg-white text-grey-8 q-py-xs" height-hint="58">
      <q-toolbar>
        <!-- <q-btn
          flat
          dense
          round
          @click="toggleLeftDrawer"
          aria-label="Menu"
          icon="menu"
        />-->

        <q-btn flat no-caps no-wrap class="q-ml-xs" v-if="$q.screen.gt.xs">
          <q-icon :name="fabYoutube" color="red" size="28px" />
          <q-toolbar-title shrink class="text-weight-bold">FORM EDITOR</q-toolbar-title>
        </q-btn>

        <q-space />

        <q-space />

        <div class="q-gutter-sm row items-center no-wrap">
          <q-btn round dense flat color="grey-8" icon="video_call" v-if="$q.screen.gt.sm">
            <q-tooltip>Create a video or post</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="apps" v-if="$q.screen.gt.sm">
            <q-tooltip>Apps</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="message" v-if="$q.screen.gt.sm">
            <q-tooltip>Messages</q-tooltip>
          </q-btn>
          <q-btn round dense flat color="grey-8" icon="notifications">
            <q-badge color="red" text-color="white" floating>2</q-badge>
            <q-tooltip>Notifications</q-tooltip>
          </q-btn>
          <q-btn round flat>
            <q-avatar size="26px">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
            </q-avatar>
            <q-tooltip>Account</q-tooltip>
          </q-btn>
        </div>
      </q-toolbar>
    </q-header>

    <q-page-container>
      <q-page class="q-pb-md q-ma-md">
        <div class="row">
          <div>
            <q-btn-group unelevated>
              <q-btn unelevated color="accent" icon="zoom_in" @click="paperZoomin()" />
              <q-btn unelevated color="accent" icon="zoom_out" @click="paperZoomout()" />
              <q-btn unelevated color="accent" icon="update" @click="resetZoom()" />
            </q-btn-group>
            <div id="myholder" class="bg-grey" style="width:900px;height:900px"></div>
          </div>
          <div>
            <p>
              mouse x : {{mouse}}
              <br />
              paper: {{paperDragging}}
            </p>
          </div>
        </div>
      </q-page>
    </q-page-container>
  </q-layout>
</template>


<script>
import * as joint from "jointjs";
import dagre from 'dagre';
import graphlib from 'graphlib';


export default {
  name: "Flows",
  components: {},
  mounted() {
    this.setupJointjs();
  },
  data() {
    return {
      result: {},
      value: "",
      modelAddUnique: [],
      drag: true,
      toolbar: true,
      mymap: {},
      zoomLevel: 1,
      graph: {},
      paper: {},
      mouse: {
        x: 0,
        y: 0,
      },
      paperDragging: {
        move: false,
        initPoint: {},
      },
      flows: [
        {
          name: "startflow",
          type: "entry",
          script: 'System.out.println("i am startflow")',
          next: "zerosx",
        },
        {
          name: "zerosx",
          type: "condition",
          script: "2>3",
          next_yes: "p1",
          next_no: "p2",
        },
        {
          name: "p1",
          type: "process",
          script: 'System.out.println("i am p1")',
          next: "p2",
        },
        {
          name: "p2",
          type: "process",
          script: 'System.out.println("i am p2")',
          next: "startflow",
        },
      ],
      flowsShapes: {},
    };
  },
  methods: {
    setupJointjs() {
      var namespace = joint.shapes;

      this.graph = new joint.dia.Graph({}, { cellNamespace: namespace });

      this.paper = new joint.dia.Paper({
        el: document.getElementById("myholder"),
        model: this.graph,
        width: 900,
        height: "80vh",
        gridSize: 1,
        cellViewNamespace: namespace,
      });
      console.log(this.paper);
      window.paper = this.paper; //attach to window

      //enable paper dragging
      this.enablePaperDragging();
      var rect = new joint.shapes.standard.Rectangle();
      rect.position(100, 30);
      rect.resize(100, 40);
      rect.attr({
        body: {
          fill: "blue",
        },
        label: {
          text: "Hello",
          fill: "white",
        },
      });
      rect.addTo(this.graph);

      var rect2 = rect.clone();
      rect2.translate(300, 0);
      rect2.attr("label/text", "World!");
      rect2.addTo(this.graph);
      //
      var amount = new joint.shapes.erd.Derived({
        position: { x: 440, y: 80 },
        attrs: {
          text: {
            fill: "#ffffff",
            text: "Amount",
            letterSpacing: 0,
            style: { textShadow: "1px 0 1px #333333" },
          },
          ".inner": {
            fill: "#fca079",
            stroke: "none",
            display: "block",
          },
          ".outer": {
            fill: "#464a65",
            stroke: "#fe854f",
            "stroke-dasharray": "3,1",
            filter: {
              name: "dropShadow",
              args: { dx: 0, dy: 2, blur: 2, color: "#222138" },
            },
          },
        },
      });
      amount.addTo(this.graph);

    

      //==draw shapes
      this.drawShapes();
      //== join shapes
      this.JoinShapes();
      //== graph layout
      this.directGraphLayout();

      var link = new joint.shapes.standard.Link();
      link.source(rect);
      link.target(rect2);
      link.addTo(this.graph);


    },
    directGraphLayout(){
            var graphBBox = joint.layout.DirectedGraph.layout(this.graph, {
        dagre: dagre,
    graphlib: graphlib,
        nodeSep: 50,
        edgeSep: 80,
        rankDir: "TB",
      });
      console.log("x:", graphBBox.x, "y:", graphBBox.y);
      console.log("width:", graphBBox.width, "height:", graphBBox.height);
    },
    drawShapes() {
      //Darw the shapes
      for (var i = 0; i < this.flows.length; i++) {
        var rect = new joint.shapes.standard.Rectangle();
        rect.position(100 + 200, 30 + 100);
        rect.resize(100, 40);
        rect.attr({
          body: {
            fill: "blue",
          },
          label: {
            text: "" + this.flows[i].name,
            fill: "white",
          },
        });
        rect.addTo(this.graph);
        //add this to flowsShapes repo
        this.flowsShapes[this.flows[i].name] = rect;
      }
    },
    JoinShapes() {
      //join the shapes
      for (var i = 0; i < this.flows.length; i++) {
        if (this.flows[i].next != "") {

          if(this.flows[i].type == 'condition') 
          {

          var link = new joint.shapes.standard.Link();
          link.source(this.flowsShapes[this.flows[i].name]);
          link.target(this.flowsShapes[this.flows[i].next_yes]);
          //link.router('orthogonal');
          link.connector('jumpover', { size: 10 });

          link.addTo(this.graph);

           var link2 = new joint.shapes.standard.Link();
           link2.source(this.flowsShapes[this.flows[i].name]);
           link2.target(this.flowsShapes[this.flows[i].next_no]);
           //link2.router('orthogonal');
           link2.connector('jumpover', { size: 10 });

          link2.addTo(this.graph);

          }else{ 

          var link3 = new joint.shapes.standard.Link();
          link3.source(this.flowsShapes[this.flows[i].name]);
          link3.target(this.flowsShapes[this.flows[i].next]);
          //link3.router('orthogonal');
          link3.connector('jumpover', { size: 10 });
          link3.addTo(this.graph);

          }
        }
      }
    },
    paperZoomin() {
      this.zoomLevel = Math.min(3, this.zoomLevel + 0.2);
      var size = this.paper.getComputedSize();
      this.paper.translate(0, 0);
      this.paper.scale(
        this.zoomLevel,
        this.zoomLevel,
        size.width / 2,
        size.height / 2
      );
    },
    paperZoomout() {
      this.zoomLevel = Math.min(3, this.zoomLevel - 0.2);
      var size = this.paper.getComputedSize();
      this.paper.translate(0, 0);
      this.paper.scale(
        this.zoomLevel,
        this.zoomLevel,
        size.width / 2,
        size.height / 2
      );
    },
    resetZoom() {
      this.zoomLevel = 1;
      var size = this.paper.getComputedSize();
      this.paper.translate(0, 0);
      this.paper.scale(
        this.zoomLevel,
        this.zoomLevel,
        size.width / 2,
        size.height / 2
      );
    },
    enablePaperDragging() {
      //get event when cursor is clicked on balnk page.
      //add paper event hadler
      this.paper.on(
        "blank:pointerdown",
        (event, x, y) => {
          this.paperDragging.move = true;
          console.log("blank:pointerdown", x, y);
          this.paperDragging.initPoint = this.paper.clientToLocalPoint({
            x: event.clientX,
            y: event.clientY,
          });
        },
        this
      );
      //Mouse up event
      this.$el.addEventListener(
        "mouseup",
        (event) => {
          this.paperDragging.move = false;
          console.log("event", event);
        },
        this
      );
      //paper drag when mouse move
      this.$el.addEventListener(
        "mousemove",
        (evt) => {
          // this.mouse.x = evt.clientX;
          // this.mouse.y = evt.clientY;
          // this.mouse.offy = evt.offsetY;
          // this.mouse.offx = evt.offsetX;
          //var newPoints = {x:this.paperPoints.x }

          if (this.paperDragging.move) {
            const scale = this.paper.scale();
            // console.log(evt, this.canvasDragPosition);
            this.paper.translate(
              evt.offsetX - this.paperDragging.initPoint.x * scale.sx,
              evt.offsetY - this.paperDragging.initPoint.y * scale.sy
            );
            this.paper.trigger("translate:changed");
          }
        },
        this
      );
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .maincard {
  }

  .title {
    font-size: 1.4rem;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 1rem;
  }
}

.smartphone {
  position: relative;
  width: 360px;
  height: 640px;
  margin: auto;
  border: 16px black solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
  overflow-y: scroll;
  background: rgb(252, 249, 249);
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(8, 8, 8);
  border-radius: 10px;
}

/* The circle on the bottom of the device */
/* .smartphone:after {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
} */

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: white;
}
</style>