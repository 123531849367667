<template>
  <q-page class="q-pb-md">
    <div class="row q-gutter-md" style="width:100%;margin:auto;">
      <q-card class="my-card">
        <q-card-section class="bg-primary text-white">
          <div class="text-h6">Zone Management</div>
          <div class="text-subtitle2">Manage the zones in the system download and upload the zones files</div>
        </q-card-section>
        <q-separator />

        <q-card-actions align="right">
          <q-btn flat @click="api_downloadZone()">Download Zones</q-btn>
          <q-btn flat @click="selectuploadZone()">Upload Zones</q-btn>
        </q-card-actions>
      </q-card>
    </div>
    <loader v-model="loading"></loader>
  </q-page>
</template>

<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "UserSetting",
  components: {
    loader,
  },
  data() {
    return {};
  },
  methods: {
    api_downloadZone() {
      var urlx = ULRrepo.ZONE_DOWNLOAD;
      window.open(urlx);
    },
    selectuploadZone() {
      var input = document.createElement("input");
      input.type = "file";
      input.name = "uploaded_file";
      input.onchange = (e) => {
        var file = e.target.files[0];
        console.log("selectFile", file);
        var fileUrl = window.URL.createObjectURL(file);
        console.log("fileUrl", fileUrl);
        //upload the file
        this.api_uploadZone(file);
      };
      input.click();
    },
    api_uploadZone(uploadedFile) {
      this.loading = true;
      var formData = new FormData();
      formData.append("uploaded_file", uploadedFile);
      axios
        .post(ULRrepo.ZONE_UPLOAD, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
  },
};
</script>
