<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Complete COD Remmitance</div>

    <!-- main card -->
    <div class="card q-pa-md" style="width:80%;margin:auto">
      <div class="row">
        <!-- <q-input class="q-ma-md" style="width:300px" v-model="searchText" outlined dense placeholder="Search User with name, mobile, email" />
        <q-btn unelevated dense style="width:150px" @click="searchData()" class="q-ma-md" color="primary" label="Search company" no-caps />
        <q-btn unelevated dense style="width:150px" to="/companynew" class="q-ma-md" color="primary" label="Add New Company" no-caps /> -->
      </div>

      <div style="width:100%; overflow-x: scroll;" class="q-mt-md">
     
                <!-- Table -->
      <table class="styled-table">
        <thead>
          <tr>
            <th>Created At</th>
            <th>company_name</th>
            <th>txn_id</th>
            <th>description</th>
            <th>amount</th>
            <th>status</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>{{new Date(item.created_at).toLocaleString()}}</td>
            <td>{{item.company.company_name}}</td>
            <td>{{item.txn_id || ""}}</td>
            <td>
                <span v-if="item.status == 'done'"> {{item.description}} </span>
               <q-input v-if="item.status != 'done'" v-model="item.description"  outlined dense type="textarea" />
            </td>
            <td>{{item.amount || ""}}</td>
            <td>{{item.status || ""}}</td>
            <td>
              <div><q-btn style="width:200px" color="green"  @click="api_approveRemmitance(item)" icon="save" label="Remmitance Done" no-caps unelevated />
              </div></td>
          </tr>
        </tbody>
        <tfoot>
    <tr >
      <td colspan="10" class="text-center"> <q-btn-group rounded>
      <q-btn no-caps v-if="pageno>0" @click="previousPage()" color="green" rounded  icon="chevron_left" label="Previous"/>
      <q-btn no-caps  v-if="!lastpage" @click="nextPage()" color="green" rounded  icon-right="navigate_next" label="Next" />
    </q-btn-group></td>
    </tr>
  </tfoot>
      </table>
      <!-- Table End -->
      </div>
    </div>
    <loader v-model="loading"></loader>
  </q-page>
</template>

<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "remittanceApprove",
  components: {
    loader,
  },
  mounted() {

    this.api_loadData();
  },
  data() {
    return {
      lastpage:false,
      pageno:0,
      searchText: "",
      data: [],
      
    };
  },
  methods: {
      nextPage() {
      this.pageno++;
      this.api_loadData();
    },
    previousPage() {
      if(this.pageno>0){
      this.pageno--;
      this.api_loadData();
      }
    },
    api_loadData() {
      var queryParam = {
        orders_v3_cod_remmitance: {
          page: this.pageno,
          _desc: "id",
          _filters: {},
        },
      };
      this.loading = true;
      axios
        .post(ULRrepo.QUERY, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data = response.data.data;

            //calucate last page
            var totalRecords = response.data.totalRecords;
            var totalPages = Math.round(totalRecords / 25);
            if (this.pageno >= totalPages) {
              this.lastpage = true;
            } else {
              this.lastpage = false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
    api_approveRemmitance(item){

        this.loading=true;

        axios.post(ULRrepo.COD_MAN_APPROVE,item )
        .then(response => {
         if (response.status == 200) {
          console.warn(response.data);
          this.showSuccess(response.data.message);
          this.api_loadData();
        }
        })
        .catch(error => {
        console.error(error);
        this.showError(error.response.data.message);
        })
        .then(() => {
        console.warn('');
        this.loading=false;
        });

    }
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>