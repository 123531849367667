<template>
  <q-page class="flex flex-center bg-white">
    <div class="row">
      <div class="col">
        <img alt="Quasar logo" src="@/assets/dev.gif" style="width: 500px; height: 400px" />
      </div>
      <div class="col">
        <div class="text-h6">Want to integrate with your product ?</div>
        <div class="q-mt-md">Generate your API KEY</div>
        <div class="row">
          <q-input filled class="q-mr-xs q-mt-xs" v-model="apiKey" label="api key" dense style="width: 300px;">
          </q-input>
          <q-btn @click="api_genKey()" color="primary" label="Generate" class="q-mr-xs q-mt-xs" />
        </div>
        <div class="q-mt-lg text-subtitle1">
          View API doc
          <a target="_blank" href="http://apidoc.shippingwala.in/">From Here</a>
        </div>
      </div>
    </div>

    <loader v-model="loading"></loader>
  </q-page>
</template>

<style>
</style>

<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "Developer",
  components: {
    loader,
  },
  mounted() {
    this.api_getKey();
  },
  data() {
    return {
      apiKey: "",
    };
  },
  methods: {
    api_getKey() {
      this.loading = true;
      axios
        .post(ULRrepo.DEVELOPER_GET_KEY, {})
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.apiKey = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_genKey() {
      this.loading = true;
      axios
        .post(ULRrepo.DEVELOPER_GENKEY, {})
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.apiKey = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
  },
};
</script>
