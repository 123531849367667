<template>
  <q-page class="q-pb-md">
    <div v-if="formData.id == undefined" class="text-h4 text-weight-bold q-ma-lg">New Orders</div>
    <div v-if="formData.id != undefined" class="text-h4 text-weight-bold q-ma-lg">{{formData.order_status}} Order {{formData.order_number}}</div>

    <!-- main card -->
    <div class="card bg-indigo q-pa-md" style="width:80%;margin:auto">
      <q-stepper v-model="step" ref="stepper" alternative-labels color="primary" animated>
        <q-step :name="1" title="Shipping info" icon="settings" :done="step > 1">
          <!-- pickup address and delivery address -->
          <div class="row">
            <!-- pickup address -->
            <div class="col-12 col-md card q-mr-md bg-indigo-3">
              <div class="text-h6 flex-grow text-center">Pickup Address</div>
              <!-- info form -->
              <div class="row" style="width:100%">
                <div class="inputs col-12">
                  <q-btn @click="warehouseDialog=true" color="primary" class="full-width" no-caps label="Select Warehouse" />
                </div>

              <div class="inputs col-12">
                  <span class="q-mb-md">Order Number</span>
                  <q-input v-model="formData.order_number" outlined dense />
                </div>
                <div class="inputs col-12">
                  <span class="q-mb-md">Name</span>
                  <q-input v-model="formData.pickup.name" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">Address line 1</span>
                  <q-input v-model="formData.pickup.address_line1" type="textarea" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">Address line 2</span>
                  <q-input v-model="formData.pickup.address_line2" type="textarea" outlined dense />
                </div>
                <div class="inputs col-12">
                  <span class="q-mb-md">LandMark</span>
                  <q-input v-model="formData.pickup.landmark" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">Pincode</span>
                  <q-input @blur="api_pincodeInfo(formData.pickup.pincode,'pik')" v-model="formData.pickup.pincode" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">State</span>
                  <q-input v-model="formData.pickup.state" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">City</span>
                  <q-input v-model="formData.pickup.city" outlined dense />
                </div>

                <div class="inputs col-6">
                  <span class="q-mb-md">Mobile</span>
                  <q-input v-model="formData.pickup.mobile" outlined dense />
                </div>
              </div>
            </div>
            <!-- <q-separator class="bg-blue" vertical /> -->
            <!-- delivery address -->
            <div class="col-11 col-md bg-light-green-2 card">
              <div class="text-h6 text-center">Delivery Address</div>
              <!-- info form -->
              <div class="row" style="width:100%">
                <div class="inputs col-12">
                  <q-btn @click="dropLocationDialog=true" color="primary" class="full-width" no-caps label="Select DropLocation" />
                </div>

                <div class="inputs col-12">
                  <span class="q-mb-md">Customer Name</span>
                  <q-input v-model="formData.delivery.name" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">Address line 1</span>
                  <q-input v-model="formData.delivery.address_line1" type="textarea" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">Address line 2</span>
                  <q-input v-model="formData.delivery.address_line2" type="textarea" outlined dense />
                </div>
                <div class="inputs col-12">
                  <span class="q-mb-md">LandMark</span>
                  <q-input v-model="formData.delivery.delivery" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">Pincode</span>
                  <q-input @blur="api_pincodeInfo(formData.delivery.pincode,'del')" v-model="formData.delivery.pincode" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">State</span>
                  <q-input v-model="formData.delivery.state" outlined dense />
                </div>
                <div class="inputs col-6">
                  <span class="q-mb-md">City</span>
                  <q-input v-model="formData.delivery.city" outlined dense />
                </div>

                <div class="inputs col-6">
                  <span class="q-mb-md">Mobile</span>
                  <q-input v-model="formData.delivery.mobile" outlined dense />
                </div>
              </div>
            </div>
          </div>
        </q-step>
        <!-- order details  -->
        <q-step :name="2" title="Order Details" icon="create_new_folder" :done="step > 2">
          <!-- order info -->

          <!-- info form -->
          <div class="row" style="width:100%;">
            <div class="text-h6 col-12">Shipment Method</div>
            <div class="inputs col-3">
              <span class="q-mb-md">Shipment speed</span>
              <q-select v-model="formData.shipping_speed" dense outlined :options="shipmentSpeed" />
            </div>
            <div class="text-h6 col-12">Payment Method</div>
            <div class="inputs col-3">
              <span class="q-mb-md">Payment mode</span>
              <q-select v-model="formData.payment_method" dense outlined :options="paymentMethod" />
            </div>
            <div v-if="formData.payment_method == 'cod'" class="inputs col-6">
              <span class="q-mb-md">COD Amount (this amount is calcuated from the order value)</span>
              <q-input readonly v-model="formData.cod_amount" outlined dense />
            </div>
            <div class="text-h6 col-12">Package Info</div>
            <div class="inputs col-3">
              <span class="q-mb-md">Real Weight (grams)</span>
              <q-input v-model="formData.real_weight" outlined dense />
            </div>
            <div class="inputs col-3">
              <span class="q-mb-md">Length (cm)</span>
              <q-input v-model="formData.shipment_length" outlined dense />
            </div>
            <div class="inputs col-3">
              <span class="q-mb-md">Breath (cm)</span>
              <q-input v-model="formData.shipment_breadth" outlined dense />
            </div>
            <div class="inputs col-3">
              <span class="q-mb-md">Height (cm)</span>
              <q-input v-model="formData.shipment_height" outlined dense />
            </div>
            <!-- item details -->
            <div class="text-h6 col-12">
              Items details
              <span class="text-caption">(item value won't be added to COD amount)</span>
            </div>
            <!-- single item -->
            <div style="border-style: solid;border-color: grey;" v-for="(item,index) in formData.items" :key="index" class="inputs row col-12 q-pb-none q-mb-none">
              <div class="col-3 q-pb-none q-mb-none">
                <span class="q-ml-md q-mb-md">Item Name</span>
                <q-input v-model="item.item_name" outlined dense />
              </div>
              <div class="q-ml-md col-3 q-pb-none q-mb-none">
                <span class="q-mb-md">Item Quantity</span>
                <q-input v-model="item.item_qty" outlined dense />
              </div>
              <div class="q-ml-md col-3 q-pb-none q-mb-none">
                <span class="q-mb-md">Item total Value</span>
                <q-input v-model="item.item_total_value" outlined dense />
              </div>
              <div class="col q-pb-none q-mb-none q-ml-md q-mt-lg">
                <q-btn color="green" v-if="index==0" @click="newItem()" icon="add_circle" label="Add New" no-caps unelevated />
                <q-btn color="red" v-if="index !=0 " @click="removeItem(index)" icon="delete_forever" label="Delete" no-caps unelevated />
              </div>
            </div>
            <!-- single item END-->
          </div>
        </q-step>
        <!-- select courier -->
        <q-step :name="3" title="Select Courier" icon="add_comment">
          <!-- courier List -->
          <div class="row q-gutter-sm">
            <!-- courier card -->
            <q-card v-for="item in courierList" :key="item.courier_name" style="width:200px">
              <q-img width="200px" height="150px" :src="require('@/assets/couriers/'+item.code+'.png')" />

              <q-card-section>
                <div class="text-subtitle2">{{item.total_charges}}</div>
                <div class="text-subtitle2">{{item.courier_name}}</div>
                <div class="text-subtitle1 text-green">Rs. {{item.payable_price}}/-</div>
                <div v-if="item.error != undefined" class="text-subtitle1 text-red">ERROR Please contact shippingwala team</div>
              </q-card-section>

              <q-separator />

              <q-card-actions>
                <q-btn v-if="item.error == undefined" @click="api_assignOrder(item)" color="primary" class="full-width" no-caps label="Ship Now" />
              </q-card-actions>
            </q-card>
            <!-- courier card -->
          </div>
          <q-inner-loading :showing="loadingCouries" label="Please wait while we find best prices..." label-class="text-teal" label-style="font-size: 1.1em" />
        </q-step>

        <template v-slot:navigation>
          <q-stepper-navigation>
            <!-- <q-btn @click="$refs.stepper.next()" color="primary" :label="'Continue'" v-if="step !=3" />
            <q-btn v-if="step > 1" flat color="primary" @click="$refs.stepper.previous()" label="Back" class="q-ml-sm" />-->
            <q-btn @click="confirmStep1()" color="primary" :label="'Continue'" v-if="step == 1" />
            <q-btn @click="confirmStep2()" color="primary" :label="'Continue'" v-if="step ==2" />
            <q-btn v-if="step > 1" flat color="primary" @click="$refs.stepper.previous()" label="Back" class="q-ml-sm" />
          </q-stepper-navigation>
        </template>
      </q-stepper>
    </div>

    <q-dialog v-model="warehouseDialog" full-width full-height>
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select WareHouse</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <warehouse isDialog v-on:myevnet="selectWarehouse($event)"></warehouse>
      </q-card>
    </q-dialog>

    <q-dialog v-model="dropLocationDialog" full-width full-height>
      <q-card>
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">Select drop location</div>
          <q-space />
          <q-btn icon="close" flat round dense v-close-popup />
        </q-card-section>
        <droplocation isDialog v-on:myevnet="selectDropLocation($event)"></droplocation>
      </q-card>
    </q-dialog>

    <loader v-model="loading"></loader>
  </q-page>
</template>


<script>
import warehouse from "@/views/warehouse/Mywarehouse.vue";
import droplocation from "@/views/dropLocation/MydropLocation.vue";

import useVuelidate from "@vuelidate/core";
import {
  required,
  minValue,
  integer,
  minLength,
  maxLength,
} from "@vuelidate/validators";

import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "NewOrder",
  components: {
    loader,
    warehouse,
    droplocation,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    console.log(JSON.stringify(this.formData));
    //check if the router has "odr" or not
    // this will open the form in edit mode
    if (this.$route.query.odr != undefined) {
      if (this.$route.query.odr != "") {
        this.api_loadOrder(this.$route.query.odr);
      }
    }
  },
  data() {
    return {
      warehouseDialog: false,
      dropLocationDialog: false,
      step: 1,
      loadingCouries: false,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["cod", "prepaid"],
      courierList: [],
      formData: {
        shipment_length: 10,
        shipment_breadth: 10,
        shipment_height: 10,
        real_weight: 10,
        payment_method: "",
        shipping_speed: "",
        cod_amount: 1,
        pickup: {
          name: "",
          address_line1: "",
          address_line2: "",
          landmark: "",
          state: "",
          city: "",
          mobile: "",
          pincode: "",
        },
        delivery: {
          name: "",
          address_line1: "",
          address_line2: "",
          landmark: "",
          state: "",
          city: "",
          mobile: "",
          pincode: "",
        },
        items: [
          {
            item_name: "",
            item_qty: 1,
            item_total_value: 1,
          },
        ],
      },
    };
  },
  watch: {
    "formData.items": {
      handler(newValue, oldValue) {
        console.log(oldValue);
        this.formData.cod_amount = 0;
        for (var i = 0; i < newValue.length; i++) {
          this.formData.cod_amount += Number(newValue[i].item_total_value);
        }
      },
      deep: true,
    },
  },
  validations() {
    return {
      formData: {
        shipment_length: { required, integer, minValueValue: minValue(2) },
        shipment_breadth: { required, integer, minValueValue: minValue(2) },
        shipment_height: { required, integer, minValueValue: minValue(2) },
        real_weight: { required, integer, minValueValue: minValue(2) },
        payment_method: { required },
        shipping_speed: { required },
        cod_amount: {
          required,
          integer,
          minValueValue: minValue(
            this.formData.payment_method == "cod" ? 10 : 0
          ),
        },
        pickup: {
          name: { required, minLength: minLength(3),maxLength: maxLength(19) },
          address_line1: { required, minLength: minLength(10) },
          state: { required },
          city: { required },
          mobile: {
            required,
            integer,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          pincode: {
            required,
            integer,
            minLength: minLength(6),
            maxLength: maxLength(6),
          },
        },
        delivery: {
          name: { required, minLength: minLength(3),maxLength: maxLength(19)  },
          address_line1: { required, minLength: minLength(10) },
          state: { required },
          city: { required },
          mobile: {
            required,
            integer,
            minLength: minLength(10),
            maxLength: maxLength(10),
          },
          pincode: {
            required,
            integer,
            minLength: minLength(6),
            maxLength: maxLength(6),
          },
        },
        items: [
          {
            item_name: { required },
            item_qty: { required, minValueValue: minValue(1) },
            item_total_value: { required, minValueValue: minValue(1) },
          },
        ],
      },
    };
  },
  methods: {
    selectWarehouse(evt) {
      console.log("select warehouse");
      this.formData.pickup.address_line1 = evt.address_line1;
      this.formData.pickup.address_line2 = evt.address_line2;
      this.formData.pickup.landmark = evt.landmark;
      this.formData.pickup.state = evt.state;
      this.formData.pickup.city = evt.city;
      this.formData.pickup.mobile = evt.mobile;
      this.formData.pickup.pincode = evt.pincode;
      this.formData.pickup.name = evt.name;
      this.warehouseDialog = false;
    },
    selectDropLocation(evt) {
      console.log("select dropLocation");
      this.formData.delivery.address_line1 = evt.address_line1;
      this.formData.delivery.address_line2 = evt.address_line2;
      this.formData.delivery.landmark = evt.landmark;
      this.formData.delivery.state = evt.state;
      this.formData.delivery.city = evt.city;
      this.formData.delivery.mobile = evt.mobile;
      this.formData.delivery.pincode = evt.pincode;
      this.formData.delivery.name = evt.name;
      this.dropLocationDialog = false;
    },
    newItem() {
      this.formData.items.push({
        item_name: "",
        item_qty: 1,
        item_total_value: 1,
      });
    },
    removeItem(index) {
      this.formData.items.splice(index, 1);
    },
    confirmStep1() {
      this.v$.$validate(); // checks all inputs
      // console.log(this.v$.formData.pickup.$invalid);
      // console.log(this.v$.formData.pickup.$errors);
      // this.showError(
      //   this.v$.$errors[0].$property + " " + this.v$.$errors[0].$message
      // );
      if (this.v$.formData.pickup.$invalid) {
        this.showError(
          "pickup:" +
            " " +
            this.v$.formData.pickup.$errors[0].$property +
            " " +
            this.v$.formData.pickup.$errors[0].$message
        );
      } else if (this.v$.formData.delivery.$invalid) {
        this.showError(
          "Delivery:" +
            " " +
            this.v$.formData.delivery.$errors[0].$property +
            " " +
            this.v$.formData.delivery.$errors[0].$message
        );
      } else {
        //allvalidation passed
        this.$refs.stepper.next();
      }
      //this.$refs.stepper.next();
    },
    confirmStep2() {
      this.v$.$validate(); // checks all inputs
      //check if all the validation are valid
      if (this.v$.formData.$invalid) {
        this.showError(
          "OrderInfo: " +
            " " +
            this.v$.formData.$errors[0].$property +
            " " +
            this.v$.formData.$errors[0].$message
        );
      } else {
        this.$refs.stepper.next();
        this.api_addOrder();
      }
    },
    api_addOrder() {
      this.loading = true;
      axios
        .post(ULRrepo.ORDER_CREATE, this.formData)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
            // now append the resonse order number to formdata
            this.formData = response.data.data;

            //move to next step
            this.$refs.stepper.next();
            this.api_fetchCouriers();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_fetchCouriers() {
      this.courierList = [];
      this.loadingCouries = true;
      var request = {
        pickup_pincode: this.formData.pickup.pincode,
        drop_pincode: this.formData.delivery.pincode,
        payment_method: this.formData.payment_method,
        shipping_speed: this.formData.shipping_speed,
        shipment_weight: this.formData.real_weight,
        shipment_length: this.formData.shipment_length,
        shipment_breadth: this.formData.shipment_breadth,
        shipment_height: this.formData.shipment_height,
        order_value: this.formData.order_value,
      };
      axios
        .post(ULRrepo.AVALAIBLE_COURIER, request)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.courierList = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loadingCouries = false;
        });
    },
    api_loadOrder(ornum) {
      this.loading = true;
      var queryParam = {
        orders_v3: {
          page: 0,
          _desc: "id",
          _filters: {
            order_number: ` = '${ornum}'`,
          },
        },
      };

      axios
        .post(ULRrepo.QUERYC, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.formData = response.data.data[0];
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_assignOrder(courier) {
      var request = {
        courier_id: courier.courier_id,
        order_number: this.formData.order_number,
        id:this.formData.id
      };

      this.loading = true;
      axios
        .post(ULRrepo.ASSIGN_COURIER, request)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
            this.$router.push("/orderlist");
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_pincodeInfo(pincode, type) {
      this.loading = true;
      this.tmpref = type;
      axios
        .post(ULRrepo.GENERIC_PINCODEINFO + pincode, {})
        .then((response) => {
          if (response.status == 200) {
            //this.showSuccess(response.data.message);
            if (response.data.length > 0) {
              var pininfo = response.data[0];

              if (this.tmpref == "del") {
                this.formData.delivery.state = pininfo.state;
                this.formData.delivery.city = pininfo.city;
              } else {
                this.formData.pickup.state = pininfo.state;
                this.formData.pickup.city = pininfo.city;
              }
            } else {
              this.showError("Invalid Pincode");
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>