<!-- eslint-disable vue/no-mutating-props -->

<template>
 
    <q-card :class="showError ? 'bg-red-4':''">
      <q-item>
        <q-item-section>
          <q-item-label class="text-h6">{{title}}</q-item-label>
          <q-item-label caption>{{description}}</q-item-label>
        </q-item-section>
      </q-item>
    </q-card>

</template>

<script>

export default {
  name: "VTitle",
  components: {},
   props: {
     schema:Object,
      title:String,
      description:String,
   },
  
  data() {
    return {
    };
  },
};
</script>
