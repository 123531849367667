<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Pricing Controller</div>

    <!-- category selector  -->
    <div class="card q-ma-md q-pb-md" style="max-width:400px">
      <div class="text-h6 col-12 q-ml-md">Select Category</div>
      <div class="row q-ml-md">
        <div class="col-6">
          <span class="q-mb-md">Category</span>
          <q-select dense outlined v-model="selectedCategory" :options="categoryList" option-label="category_code" />
        </div>
        <div class="col-6 flex flex-center q-pt-md">
          <q-btn @click="api_loadCategory()" style="width:90%" color="primary" unelevated label="re-load" />
        </div>
      </div>
    </div>
    <!-- category selector end -->

    <div style="width:100%" class="row justify-center q-pa-md">

     <pricingZone :selectedCategory="selectedCategory.category_code"/>
      <shippingSpeed :selectedCategory="selectedCategory.category_code"/>
      <paymentMethod :selectedCategory="selectedCategory.category_code"/>
      <courierPricing :selectedCategory="selectedCategory.category_code"/>
     <pricingWeight :selectedCategory="selectedCategory.category_code"/>
  
    </div>
  </q-page>
  <loader v-model="loading"></loader>
</template>


<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";
//component
import shippingSpeed from './components/PricingShippingSpeed.vue';
import paymentMethod from './components/PricingPaymentMethod.vue';
import courierPricing from './components/PricingCourier.vue';
import pricingWeight from './components/PricingWeight.vue';
import pricingZone from './components/PricingZone.vue';


export default {
  mixins: [globalmix],
  name: "Pricing",
  components: {
    loader,
    shippingSpeed,
    paymentMethod,
    courierPricing,
    pricingWeight,
        pricingZone

  },
  mounted() {
    this.api_loadCategory();
  },
  data() {
    return {
      categoryList: [],
      selectedCategory: "",
    };
  },
  methods: {
    api_loadCategory() {
      var queryParam = {
        categories: {
          page: 0,
          _desc: "id",
          limit:150,
          _filters: {},
        },
      };
      this.loading = true;
      axios
        .post(ULRrepo.QUERY, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.categoryList = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
      border-style: groove;

}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 5px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>