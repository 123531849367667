<template>
  <q-page class="q-pb-md">
    <div class="text-h4 row text-weight-bold q-ma-lg q-gutter-md">
      <!-- Wallet Balance -->
      <q-card class="carddesign" flat bordered>
        <div class="row">
          <div style="width:150px;height:150px">
            <q-img style="    margin-top: 15%;" class="col" src="@/assets/walleta.gif" />
          </div>
          <div class="col q-ma-md text-center">
            <div class="text-h6 text-italic">Wallet Balance</div>
            <div class="text-h4 q-ma-md text-italic">₹ {{walletBalance}}/-</div>
          </div>
        </div>
      </q-card>
      <!-- Wallet Balance -->
      <q-card class="carddesign" flat bordered>
        <div class="row">
          <div style="width:150px;height:150px">
            <q-img style="    margin-top: 15%;" class="col" src="@/assets/payment-terminal.gif" />
          </div>
          <div class="col q-ma-md text-center">
            <div class="text-h6 text-italic">Add money to your wallet</div>
            <div class="text-h4 q-mt-md text-italic">
              <q-input v-model="rechargeAmount" outlined dense placeholder="enter the amount. Minimum reacharge is 200" />
            </div>
            <div class="text-h4 text-italic">
              <q-btn @click="btn_rechangeNow()" dense style="width:150px" color="green" label="Recharge now" no-caps />
            </div>
          </div>
        </div>
      </q-card>
    </div>
    <!-- main contenet -->
    <div class="row card q-ma-lg justify-left q-pa-md q-mb-md" style="width:75%;margin:auto">
      <!-- Table  -->
      <div style="width:100%" class="q-mt-md">
           <!-- Table -->
      <table class="styled-table">
        <thead>
          <tr>
            <th>Created At</th>
            <th>Order Number</th>
            <th>Transaction number</th>
            <th>description</th>
            <th>transaction type</th>
            <th>amount</th>
            <th>balance</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>{{new Date(item.created_at).toLocaleString()}}</td>
            <td>{{item.order_number}}</td>
            <td>{{item.transaction_number || ""}}</td>
            <td>{{item.description || "" }}</td>
            <td>{{item.transaction_type || ""}}</td>
            <td>{{item.amount || ""}}</td>
            <td>{{item.balance || ""}}</td>
          </tr>
        </tbody>
        <tfoot>
    <tr >
      <td colspan="10" class="text-center"> <q-btn-group rounded>
      <q-btn no-caps v-if="pageno>0" @click="previousPage()" color="green" rounded  icon="chevron_left" label="Previous"/>
      <q-btn no-caps  v-if="!lastpage" @click="nextPage()" color="green" rounded  icon-right="navigate_next" label="Next" />
    </q-btn-group></td>
    </tr>
  </tfoot>
      </table>
      <!-- Table End -->

      </div>
    </div>

    <loader v-model="loading"></loader>
  </q-page>
</template>


<script>
require("./tableStyle.css");

import razorpay from "./razorpay";
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [razorpay, globalmix],

  name: "Wallet",
  components: {
    loader,
  },
  mounted() {
    this.api_loadBalance();
    this.api_loadLedger();
  },
  data() {
    return {
      lastpage:false,
      pageno:0,
      walletBalance: 0,
      rechargeAmount: "200",
      data: [],
    };
  },
  methods: {
    nextPage() {
      this.pageno++;
      this.api_loadLedger();
    },
    previousPage() {
      if(this.pageno>0){
      this.pageno--;
      this.api_loadLedger();
      }
    },
    btn_rechangeNow() {
      this.api_initRaZorPay(this.rechargeAmount);
    },
    api_loadBalance() {
      this.loading = true;
      axios
        .post(ULRrepo.USER_BALANCE, {})
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.walletBalance = response.data.data.balance;
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_loadLedger() {
      var queryParam = {
        wallet_ledger: {
          page: this.pageno,
          _desc: "id",
          _filters: {},
        },
      };
      this.loading = true;
      axios
        .post(ULRrepo.QUERYC, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data = response.data.data;

            //calucate last page
            var totalRecords = response.data.totalRecords;
            var totalPages = Math.round(totalRecords / 25);
            if (this.pageno >= totalPages) {
              this.lastpage = true;
            } else {
              this.lastpage = false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.carddesign {
  max-width: 500px;
  border-radius: 10px;
}
</style>