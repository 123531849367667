<template>
  <q-page class="q-pb-md">
    <div class="text-h4 row text-weight-bold q-ma-lg q-gutter-md">
      <q-card class="carddesign" flat bordered>
        <div class="row">
          <div style="width:150px;height:150px" class="flex flex-center">
            <q-img style=" margin-top: 15%;" class="col" src="@/assets/calcuate.gif" />
          </div>
          <div class="col q-ma-md text-center">
            <div class="text-h6 text-italic">Calculate Shipping cost</div>
            <div>
              <div class="text-h4 q-mt-md text-italic row">
                <q-input v-model="formdata.pickup_pincode" class="q-ml-md" outlined  label="enter pickup pincode" />
                <q-input class="q-ml-md" v-model="formdata.drop_pincode" outlined  label="enter delivery pincode" />
              <q-select  class="q-ml-md" v-model="formdata.payment_method"  outlined :options="paymentMethod" />
              <q-select  class="q-ml-md" v-model="formdata.shipping_speed"  outlined :options="shipmentSpeed" />
              <q-input class="q-ml-md" v-model="formdata.order_value" outlined  label="parcel order value" />
              </div>
              <div class="text-h4 q-mt-md text-italic row">
                <q-input class="q-ml-md" v-model="formdata.shipment_weight" outlined  label="parcel weight" />
                <q-input class="q-ml-md" v-model="formdata.shipment_length" outlined  label="parcel length" />
                <q-input class="q-ml-md" v-model="formdata.shipment_breadth" outlined  label="parcel breadth" />
                <q-input class="q-ml-md" v-model="formdata.shipment_height" outlined  label="parcel height" />
              </div>
            </div>
            <div class="text-h4 text-italic">
              <q-btn @click="api_fetchCouriers()" dense style="width:150px" color="green" label="Calcualte" no-caps />
            </div>
          </div>
        </div>
      </q-card>
    </div>
    <!-- main contenet -->
    <div class="row card q-ma-lg justify-left q-pa-md q-mb-md" style="width:75%;margin:auto">
      

            <!-- courier card -->
            <q-card class="q-ma-md" v-for="item in courierList" :key="item.courier_name" style="width:200px">
              <q-img width="200px" height="150px" :src="require('@/assets/couriers/'+item.code+'.png')" />

              <q-card-section>
                <div class="text-subtitle2">{{item.courier_name}}</div>
                <div class="text-subtitle1 text-green">Rs. {{item.payable_price}}/-</div>
              </q-card-section>
            </q-card>
            <!-- courier card -->


    </div>
        <loader v-model="loading"></loader>
  </q-page>
</template>


<script>


import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
    mixins: [globalmix],
  name: "ShippingCostCalulator",
  components: {
    loader
  },
  mounted() {},
  data() {
    return {
      courierList:[],
       shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["cod", "prepaid"],
      formdata: {
        pickup_pincode: "",
        drop_pincode: "",
        payment_method: "",
        shipping_speed: "",
        shipment_weight: "10",
        shipment_length: "10",
        shipment_breadth: "10",
        shipment_height: "10",
        order_value: "100",
      },
    };
  },
  methods: {

     api_fetchCouriers() {
      this.courierList = [];
      this.loading = true;

       axios.post(ULRrepo.AVALAIBLE_COURIER, this.formdata)
       .then(response => {
        if (response.status == 200) {
           console.warn(response.data);
           this.courierList = response.data;
           
       }
       })
       .catch(error => {
       console.error(error);
       this.showError(error.response.data.message);       
       })
       .then(() => {
       console.warn('');
       this.loading = false;
       
       });



    },

  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.carddesign {
  /* max-width: 500px; */
  border-radius: 10px;
}
</style>