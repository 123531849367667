<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Account Settings</div>

    <!-- main card -->
    <div class="card q-pa-md" style="width:80%;margin:auto">
    
     <q-tabs
        v-model="tab"
        inline-label
        class="bg-green-4 text-white shadow-2"
         align="justify"

      >
        <q-route-tab to="/mcompanyinfo" name="codrem" icon="business" label="Company Info" />
        <q-route-tab to="/muserinfo" name="profile" icon="face" label="Profile" />
       
      </q-tabs>
      
     <router-view></router-view>

    </div>
  </q-page>
</template>


<script>
export default {
  name: "UserSetting",
  components: {},
  data() {
    return {
     tab:"codrem"
    };
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>