<template>
  <q-page class="q-pb-md">
    <div class="row q-ma-lg justify-left q-pa-md q-mb-md" style="width:100%;margin:auto">
      <div class="inputs text-h6 col-12">My Profile</div>

      <div v-if="false"  class="inputs col-4">
        <span class="q-mb-md">User Name</span>
        <q-input v-model="formData.user_name" readonly outlined dense />
      </div>
      <div class="inputs col-4">
        <span class="q-mb-md">Email</span>

        <q-input v-model="formData.email" readonly outlined dense />
      </div>
      <div class="inputs col-4">
        <span class="q-mb-md">First name</span>
        <q-input v-model="formData.first_name" outlined dense />
      </div>
      <div class="inputs col-4">
        <span class="q-mb-md">Last name</span>
        <q-input v-model="formData.last_name" outlined dense />
      </div>

      <div class="inputs col-4">
        <span class="q-mb-md">Password</span>

        <q-input type="password" v-model="pass.newPass" outlined dense />
      </div>
      <div class="inputs col-4">
        <span class="q-mb-md">Confirm Password</span>

        <q-input type="password" v-model="pass.confirmPass" outlined dense />
      </div>

      <q-separator style="width: 100%;" />
      <div class="col-12 q-mt-md text-right">
        <q-btn @click="api_saveProfile()" color="primary" no-caps label="Save Changes" />
      </div>
      <q-inner-loading :showing="loading">
        <q-spinner-gears size="50px" color="primary" />
      </q-inner-loading>
    </div>
  </q-page>
</template>


<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;

export default {
  mixins: [globalmix],
  name: "UserSetting",
  components: {},
  mounted() {
    this.api_loadProfile();
  },
  data() {
    return {
      formData: {},
      pass: {
        newPass: "",
        confirmPass: "",
      },
    };
  },
  methods: {
    api_loadProfile() {
      this.loading = true;
      axios
        .post(ULRrepo.USER_PROFILE, "")
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.formData = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },

    api_saveProfile() {
      //check the password      
      if (this.pass.newPass != "" || this.pass.confirmPass != "") {
        if (this.pass.newPass != this.pass.confirmPass) {
          this.showError("Password and Confirm Password does not match");
          return;
        } else {

          this.formData.newpass = this.pass.newPass;
          this.showInfo("updating your password");
          
        }
      }

      this.loading = true;
      axios
        .post(ULRrepo.USER_PROFILE_SAVE, this.formData)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.formData = response.data.data;
            this.showSuccess("Profile updated successfully");
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError("error updating profile");

        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>