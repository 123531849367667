export default {

  /*
  * TRUE means validation is meet
  * FALSE means validaiton is not fullfilled
  */

  required: function (value ) {
      console.log("required CHECk",value);
      if (Array.isArray(value)) {
        return value.length > 0 ? true : false;
      }
      if (typeof value === 'object') {
        return Object.keys(value).length > 0 ? true : false;
      }
      if (typeof value === 'string') {
        return value.trim() != '' ? true : false;
      }
      if (typeof value === 'number') {
        return true;
      }
      //no validation meet
      return false;
  }

}