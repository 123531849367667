export var form1 = {
    title: "Course evaluation",
    description:
        "Please submit feedback regarding the course you have just completed, including feedback on course structure, content, and instructor.",
    submitButtonText: "Submit the form",
    pages: [
        {
            title: "This is page 1",
            description: "des",
            elements: [
                {
                    title: "yii",
                    description: "my dec",
                    type: "select",
                    key: "select",
                    options: ["abble", "booyy", "caat"],
                    required: true,
                    value: ""
                    
                },
                {
                    title: "Dynamic Text",
                    description: "my dec",
                    type: "text",
                    key: "text",
                    required: true,
                    value: "",
                    visible:false
                },
                {
                    title: "yii",
                    description: "my dec",
                    type: "textarea",
                    key: "textarea",
                    required: true,
                    value: "",
                },
                {
                    title: "yii",
                    description: "my dec",
                    type: "slider",
                    key: "slider",
                    required: true,
                    value: "10",
                },
                {
                    title: "yii",
                    description: "my dec",
                    type: "radio",
                    key: "radio",
                    required: true,
                    options: ["abble", "booyy", "caat"],
                    value: "",
                },
                {
                    title: "yii",
                    description: "my dec",
                    type: "check",
                    key: "check",
                    required: true,
                    options: ["abble", "booyy", "caat"],
                    value: [],
                },
                {
                    title: "yii",
                    description: "my dec",
                    type: "radiogrid",
                    key: "table",
                    required: true,
                    value: "",
                },
            ]
        },
        {
            title: "This is page 2",
            description: "des",
            elements: [
                {
                    title: "yii",
                    description: "my dec",
                    type: "select",
                    key: "select",
                    options: ["abble", "booyy", "caat"],
                    required: true,
                    value: "",
                }
            ]
        }
    ]
}