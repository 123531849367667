<template>
  <q-page class="q-pb-md">
    <div class="text-h4 text-weight-bold q-ma-lg">Add New Company</div>
    <!-- <p
  v-for="error of v$.$errors"
  :key="error.$uid"
>
<strong>{{ error.$validator }}</strong>
<small> on property</small>
<strong>{{ error.$property }}</strong>
<small> says:</small>
<strong>{{ error.$message }}</strong>
    </p>-->
    <div class="row card q-ma-lg justify-left q-pa-md q-mb-md" style="width:75%;margin:auto">
      <div class="inputs text-h6 col-12">Company Info</div>

      <div class="inputs col-2">
        <span class="q-mb-md">Company Name</span>
        <q-input ref="comp" v-model="formData.company_name" outlined dense />
      </div>
      <!-- <div class="inputs col-2">
        <span class="q-mb-md">Company Code</span>
        <q-input v-model="formData.company.company_code" outlined dense />
      </div>-->
      <div class="inputs col-2">
        <span class="q-mb-md">Website URL</span>
        <q-input v-model="formData.website" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">Email</span>

        <q-input v-model="formData.email" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">Contact No.</span>

        <q-input v-model="formData.mobile" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">Pan Number</span>
        <q-input v-model="formData.pan_number" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">GST Number</span>

        <q-input v-model="formData.gst_number" outlined dense />
      </div>

      <div class="inputs inputs col-2">
        <span class="q-mb-md">City</span>

        <q-input v-model="formData.city" outlined dense />
      </div>
      <div class="inputs inputs col-2">
        <span class="q-mb-md">State</span>

        <q-input v-model="formData.state" outlined dense />
      </div>
      <div class="inputs inputs col-2">
        <span class="q-mb-md">Pincode</span>
        <q-input v-model="formData.pincode" outlined dense />
      </div>

      <div class="col-12">
        <span class="q-mb-md">Company Address</span>
        <q-input v-model="formData.address" outlined dense type="textarea" />
      </div>


      <div class="inputs text-h6 col-12">Bank Details</div>
       <div class="inputs col-2">
        <span class="q-mb-md">Account Name</span>
        <q-input v-model="formData.bank_details.account_name" outlined dense />
      </div>
       <div class="inputs col-2">
        <span class="q-mb-md">Bank Name</span>
        <q-input v-model="formData.bank_details.bank_name" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">Account Number</span>
        <q-input v-model="formData.bank_details.account_number" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">IFSC code</span>
        <q-input v-model="formData.bank_details.ifsc" outlined dense />
      </div>
    

      <div class="inputs text-h6 col-12">User Info</div>

      <!-- <div class="inputs col-2">
        <span class="q-mb-md">User Name</span>
        <q-input v-model="formData.user.user_name" outlined dense />
      </div>-->
      <div class="inputs col-2">
        <span class="q-mb-md">First Name</span>
        <q-input v-model="formData.u_first_name" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">Last Name</span>
        <q-input v-model="formData.u_last_name" outlined dense />
      </div>
      <div class="inputs col-2">
        <span class="q-mb-md">Password</span>
        <q-input v-model="formData.u_password" outlined dense />
      </div>
      <div class="inputs col-3">
        <span class="q-mb-md">Category</span>
        <q-select v-model="formData.category" emit-value option-label="category_code" option-value="category_code" dense outlined :options="categories" />
      </div>
      <div class="inputs col-3">
        <span class="q-mb-md">Status</span>
        <q-select v-model="formData.status" dense outlined :options="companyStatus" />
      </div>

      <q-separator style="width: 100%;" />
      <div class="col-12 q-mt-md text-right">
        <q-btn @click="submitForm()" color="primary" no-caps label="Save Changes" />
      </div>
    </div>
    <loader v-model="loading"></loader>
  </q-page>
</template>


<script>
import useVuelidate from "@vuelidate/core";
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "CompanyNew",
  components: {
    loader,
  },
  mounted() {
    this.api_loadCategory();

    if (this.$route.query.id != undefined) {
      if (this.$route.query.id != "") {
        this.api_loadCompany(this.$route.query.id);
      }
    }

  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      categories: [],
      companyStatus: ["email_verify_pending", "active", "blocked"],
      formData: {
        bank_details:{}
      },
    };
  },
  validations() {
    return {
    };
  },
  methods: {
    submitForm() {

      this.api_createCompany();
    },
    api_createCompany() {
      this.loading = true;
      axios
        .post(ULRrepo.CREATE_COMPANY, this.formData)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
            this.$router.push("/company");
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_loadCategory() {
      this.loading = true;
      axios
        .post(ULRrepo.GENERIC_CATEGORY, {})
        .then((response) => {
          if (response.status == 200) {
            this.categories = response.data;
          }
        })
        .catch((error) => {
          console.error(error);
          //this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_loadCompany(odr){

       var queryParam = {
        company_detail: {
          page: this.pageno,
          _desc: "id",
          _filters: {
            id : `= ${odr}`
          },
        },
      };
      this.loading = true;
      axios
        .post(ULRrepo.QUERY, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            //this.formData = response.data.data[0];
            var compdata = response.data.data[0];
            if(compdata.bank_details == undefined)
            {
              compdata.bank_details = {};
              this.formData = compdata;
            }else{
              this.formData = compdata;
            }
            
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });


    }
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>