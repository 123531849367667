<!-- eslint-disable vue/no-mutating-props -->

<template>
    
 <q-card :class="showError ? 'bg-red-4':''">
        <q-item>
            <q-item-section>
            <q-item-label>{{schema.title}} <span class="text-red">{{schema.required ? '*' : ''}}</span></q-item-label>
            <q-item-label caption>{{schema.description}}</q-item-label>
          </q-item-section>
        </q-item>
        <q-card-section class="q-pt-xs">
          <div class="q-gutter-xs row">
           <template v-for="item in schema.options" :key="item">
            <q-radio class="col-12" v-model="result[schema.key]" checked-icon="task_alt" unchecked-icon="panorama_fish_eye" :val="item" :label="item" />
            </template>
            </div>
        </q-card-section>
      </q-card>
</template>

<script>

import vcomponent from  './vComponents.js'


export default {
    mixins: [vcomponent],

  name: "Vradio",
   props: {
     schema:Object
   },
  components: {},
  data() {
    return {
      feildValue:"",
      shape: "line",
      
    };
  },
};
</script>
