<template>
  <div>
    <div id="controls">
      <button style="
    border-style: dashed;
    border-width: thick;
    padding: 10px;
    border-color: red;
    background: black;
    color: white;" onclick="window.print()">Print this page</button>
     <q-checkbox v-model="viewReturn" label="Show Return Address" />

      <br />
    </div>

    <template :key="index" v-for="(item,index)  in ordersList">
    <shiplabel class="newLine" :hideReturn="viewReturn" :order="item"/>
    </template>

    <loader v-model="loading" />
  </div>
</template>
<script>
import shiplabel from "./Shiplabel";

export default {
  components: {
    shiplabel,
  },
  mounted(){

   console.log("===>",this.$route.params.lables);
  },
  data() {
    return {
      loading: false,
      ordersList:window.toprint,
      viewReturn:true,
    };
  },
  
  methods: {
   
  }
};
</script>

<style scoped>
@media print {
  #controls {
    margin: 0;
    display: none;
  }
  .newLine {page-break-after: always;}
}
</style>