<template>
  <div class="col-12 q-pa-xs">
    <!-- title -->
    <vtitle :title="form.title" :description="form.description"></vtitle>
    <!-- Form Feilds -->
    <div>
      <!-- Pages  -->
      
          <vtitle class="q-mt-md" :title="schema.pages[currentPage].title" :description="schema.pages[currentPage].description"></vtitle>
          <template :key="item.key" v-for="item in schema.pages[currentPage].elements">
            <vselect ref="comp" class="q-ma-md" :result="result" :schema="item" :key="item.key" v-if="item.type == 'select'"></vselect>
            <vradio ref="comp" class="q-ma-md" :result="result" :schema="item" :key="item.key" v-if="item.type == 'radio'"></vradio>
            <vcheck ref="comp" class="q-ma-md" :result="result" :schema="item" :key="item.key" v-if="item.type == 'check'"></vcheck>
            <vtext  ref="comp" class="q-ma-md" :result="result" :schema="item" :key="item.key" v-if="item.type == 'text' && item.visible"></vtext>
            <vtextarea ref="comp" class="q-ma-md" :result="result" :schema="item" :key="item.key" v-if="item.type == 'textarea'"></vtextarea>
            <vslider ref="comp" class="q-ma-md" :result="result" :schema="item" :key="item.key" v-if="item.type == 'slider'"></vslider>
            <vradiogrid ref="comp" class="q-ma-md" :result="result" :schema="item" :key="item.key" v-if="item.type == 'radiogrid'"></vradiogrid>
          </template>
       

      <q-card>
        <q-item>
          <q-item-section>
            <q-item-label>DEBUG</q-item-label>
          </q-item-section>
        </q-item>
        <q-card-section class="q-pt-xs">{{result}}</q-card-section>
      </q-card>
    </div>
    <!-- Submit button -->
    <q-btn @click="submitButtonClicked()" color="primary" class="full-width q-pa-md" no-caps label="SUBMIT" />
  </div>
</template>


<script>
import vselect from "./components/viewer/Vselect.vue";
import vradio from "./components/viewer/Vradio.vue";
import vcheck from "./components/viewer/Vcheck.vue";
import vtext from "./components/viewer/Vtext.vue";
import vtextarea from "./components/viewer/Vtextarea.vue";
import vslider from "./components/viewer/Vslider.vue";
import vradiogrid from "./components/viewer/Vradiogrid.vue";
import vtitle from "./components/viewer/Vtitle.vue";

import * as form1 from "./testdata/Form1";

export default {
  name: "Form",
  components: {
    vselect,
    vradio,
    vcheck,
    vtext,
    vtextarea,
    vslider,
    vradiogrid,
    vtitle,
  },
  props: {
    schema: {
      type: Object,
      // Object or array defaults must be returned from
      // a factory function
      default: function () {
        return form1.form1;
      },
    },
  },
  data() {
    return {
      result: {},
      currentPage:0,
      form: this.schema,
      formx: {
        title: "Course evaluation",
        description:
          "Please submit feedback regarding the course you have just completed, including feedback on course structure, content, and instructor.",
        submitButtonText: "Submit the form",
        feilds: [
          {
            title: "yii",
            description: "my dec",
            type: "select",
            key: "select",
            options: ["abble", "booyy", "caat"],
            required: true,
            value: "",
          },
          {
            title: "yii",
            description: "my dec",
            type: "text",
            key: "text",
            required: true,
            value: "",
          },
          {
            title: "yii",
            description: "my dec",
            type: "textarea",
            key: "textarea",
            required: true,
            value: "",
          },
          {
            title: "yii",
            description: "my dec",
            type: "slider",
            key: "slider",
            required: true,
            value: "10",
          },
          {
            title: "yii",
            description: "my dec",
            type: "radio",
            key: "radio",
            required: true,
            options: ["abble", "booyy", "caat"],
            value: "",
          },
          {
            title: "yii",
            description: "my dec",
            type: "check",
            key: "check",
            required: true,
            options: ["abble", "booyy", "caat"],
            value: [],
          },
          {
            title: "yii",
            description: "my dec",
            type: "radiogrid",
            key: "table",
            required: true,
            value: "",
          },
        ],
      },
    };
  },
  methods: {
    submitButtonClicked(){
      this.validate();
      //check how many pages does schema contains
      var noOfPages = this.schema.pages.length;
      //check if current page is last page
      if(this.currentPage == noOfPages-1){
        //submit form
       // this.submitForm();
      }else{
        //go to next page
        this.currentPage++;
      }
    },
    validate() {
      console.log("validating..");
      //console.log(this.$refs.comp[0].hasError());

      for (var i = 0; i < this.$refs.comp.length; i++) {
        if (this.$refs.comp[i].hasError != undefined) {
          console.log("<====", this.$refs.comp[i].hasError());
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

@media screen and (max-width: 600px) {
  .maincard {
  }

  .title {
    font-size: 1.4rem;
  }
  .subtitle {
    font-size: 1rem;
    line-height: 1rem;
  }
}
</style>