<template>
    

      <q-scroll-area class="fit">
        <q-toolbar class="GPL__toolbar q-pa-none">
          <q-toolbar-title class=" row items-center text-grey-8">
            <img class="" style="width: 100%;" src="@/assets/EIG1.gif">
            <!-- <span class="q-ml-sm">Photos</span> -->
          </q-toolbar-title>
        </q-toolbar>



        <q-list v-if="adminenabled" style="border-style: dashed;" class="q-mt-md" >

         <!-- Admin menu -->
          <q-item  v-for="link in adminMenu" :key="link.text" :to="link.to" clickable class="GPL__drawer-item">
            <q-item-section avatar>
              <q-icon :name="link.icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ link.text }}</q-item-label>
            </q-item-section>
          </q-item>
          <q-separator class="q-my-md" />

        </q-list>

        <q-list v-if="!adminenabled" padding>
               

          <!-- User Menu -->
          <q-item    v-for="link in links1" :key="link.text" :to="link.to" clickable class="GPL__drawer-item">
            <q-item-section avatar>
              <q-icon :name="link.icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ link.text }}</q-item-label>
            </q-item-section>
          </q-item>



          <!-- <q-item :v-if="false" v-for="link in links2" :key="link.text" clickable class="GPL__drawer-item">
            <q-item-section avatar>
              <q-icon :name="link.icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ link.text }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-separator class="q-my-md" />

          <q-item   v-for="link in links3" :key="link.text" clickable class="GPL__drawer-item">
            <q-item-section avatar>
              <q-icon :name="link.icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ link.text }}</q-item-label>
            </q-item-section>
          </q-item>

          <q-separator class="q-my-md" /> -->

          <!-- <q-item v-if="false" clickable class="GPL__drawer-item GPL__drawer-item--storage">
            <q-item-section avatar>
              <q-icon name="cloud" />
            </q-item-section>
            <q-item-section top>
              <q-item-label>Storage</q-item-label>
              <q-linear-progress :value="storage" class="q-my-sm" />
              <q-item-label caption>2.6 GB of 15 GB</q-item-label>
            </q-item-section>
          </q-item> -->


        </q-list>
      </q-scroll-area>

</template>


<script>
export default {
  name: 'Sidebar',
  mounted(){

      var user = JSON.parse(localStorage.getItem('user'));
      if(user.user_type == 'system_admin')
      {
        this.adminenabled=true;
      }

  },
  data(){
  return{
    adminenabled:false,
    storage:10,
     links1: [
        { icon: 'insights', text: 'Insight',to:'/dash' },
        { icon: 'add_shopping_cart', text: 'New Order',to:'/neworders'  },
        { icon: 'local_shipping', text: 'Shipments',to:'/orderlist' },
        // { icon: 'people', text: 'Teams',to:'umanage' },
        { icon: 'account_balance_wallet', text: 'Wallet', to:'/Wallet' },
        { icon: 'calculate', text: 'Calculate Shipping Cost' ,to:'/shipcost' },
        { icon: 'currency_rupee', text: 'Accounts' , to:'/accounts' },
        // { icon: 'flutter_dash', text: 'Branding' },
        { icon: 'warehouse', text: 'Warehouse', to:'/mywarehouse' },
        { icon: 'pin_drop', text: 'Drop Locations', to:'/mydroplocation' },
        { icon: 'settings', text: 'Settings', to:'/mcompanyinfo' },
        { icon: 'help', text: 'Help & Feedback',to:'/help' },
        { icon: 'code', text: 'Developer',to:'/developer' },
      ],
      adminMenu:[
        { icon: 'insights', text: 'Analytics',to:'/analytics' },
        // { icon: 'notification_important', text: 'Notifications',to:'/dash' },
        { icon: 'business', text: 'Manage company',to:'company' },
        { icon: 'people', text: 'Company Users',to:'umanage' },
        // { icon: 'shopping_cart', text: 'Orders',to:'umanage' },
        { icon: 'local_shipping', text: 'Shipments',to:'/orderadmin' },
        // { icon: 'add_shopping_cart', text: 'New Order',to:'/neworders'  },
        { icon: 'rule', text: 'Approve COD Remmitance',to:'/acodremmitanceapprove'  },
        { icon: 'account_balance_wallet', text: 'User wallets', to:'/walletmaster' },
        { icon: 'calculate', text: 'Calculate Shipping Cost', to:'/shipcost' },
        { icon: 'category', text: 'Categories',to:"category" },
        { icon: 'price_check', text: 'Categories Pricing', to:"pricing" },
        { icon: 'currency_rupee', text: 'Accounts' , to:'/accounts' },
        // { icon: 'flutter_dash', text: 'Default Branding' },
         { icon: 'settings', text: 'Zone Manager', to:'zonemanage' },
      ],
      }
  },
  methods:{

  }

}
</script>