<template>
  <div style="border-style: solid">
    <!-- Title -->
    <div class="row">
      <table style="margin: 10px" id="newLine" border="1" cellpadding="5" cellspacing="0">
        <tr>
          <td width="162" align="center">
            <!-- <img :src="require('@/assets/logo_banner.png')"  style="height: 60px" /> -->
            <img style="height: 60px"  :src="'https://files.shippingwala.in/uploads/'+order.company_id+'shipment.png?'+Math.random()" @error="$event.target.src=require('@/assets/logo_banner.png')">
          </td>
          <td width="206" align="center">
            <strong>{{order.courier_name || ""}}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <!-- <barcode
              :value="order.tracking_number"
              format="CODE128"
              height="60px"
            >
              Show this if the rendering fails.
            </barcode>-->
            <!-- <img class="barcodex" id="barcode"/> -->
            <BarcodeGenerator :height="60" :value="order.tracking_number" :format="'CODE128'" :lineColor="'#000'" :elementTag="'img'" />
          </td>

          <td>AWB Number#:&nbsp; {{order.tracking_number || ""}}</td>
        </tr>
        <tr>
          <td>
            <strong>Shipping Address :</strong>
            <br />
            {{(order.delivery != undefined ? order.delivery.name : "")}}
            {{(order.delivery != undefined ? order.delivery.address_line1 : "")}}
            {{(order.delivery != undefined ? order.delivery.address_line2 : "")}}
            <b>LandMark:</b>
            {{(order.delivery != undefined ? order.delivery.landmark : "")}}
            <b>City:</b>
            {{(order.delivery != undefined ? order.delivery.city : "")}}
            <b>Pincode:</b>
            {{(order.delivery != undefined ? order.delivery.pincode : "")}}
            <br />
            <b>State:</b>
            {{(order.delivery != undefined ? order.delivery.state : "")}}
            <b>Mob:</b>
            {{(order.delivery != undefined ? order.delivery.mobile : "")}}
          </td>
          <td style="font-variant: all-petite-caps" align="center">
            <strong>{{ order.payment_method }} order</strong>
          </td>
        </tr>
        <!-- <tr>
          <td>
            <strong>Seller:</strong>&nbsp; {{ order.delivery_address.name
            }}<br />
            {{ order.delivery_address.name }}
          </td>
          <td>
            GSTIN: {{ order.delivery_address.name }}<br />
            TIN: {{ order.delivery_address.name }}<br />
            CST: {{ order.delivery_address.name }}<br />
          </td>
        </tr>-->
        <tr>
          <td colspan="2">
            <table border="1" width="100%" cellspacing="0" cellpadding="3">
              <tr>
                <td>
                  <strong>Item name</strong>
                </td>
                <td>
                  <strong>Item qty</strong>
                </td>
                <td>
                  <strong>Item value</strong>
                </td>
              </tr>
              <template :key="index" v-for="(item, index) in order.items">
                <tr>
                  <td>{{ item.item_name }}</td>
                  <td>{{ item.item_qty }}</td>
                  <td>{{ item.item_total_value }}</td>
                </tr>
              </template>
              <tr>
                <td colspan="2" align="right">
                  <strong>AMOUNT TO COLLECT &nbsp;</strong>
                </td>
                <td>&nbsp; {{ order.cod_amount }}/-</td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td colspan="2" v-if="hideReturn">
            <b>Return Address:</b>
            <br />
            {{(order.pickup != undefined ? order.pickup.name : "")}}
            {{(order.pickup != undefined ? order.pickup.address_line1 : "")}}
            {{(order.pickup != undefined ? order.pickup.address_line2 : "")}}
            <b>LandMark:</b>
            {{(order.pickup != undefined ? order.pickup.landmark : "")}}
            <b>City:</b>
            {{(order.pickup != undefined ? order.pickup.city : "")}}
            <b>Pincode:</b>
            {{(order.pickup != undefined ? order.pickup.pincode : "")}}
            <br />
            <b>State:</b>
            {{(order.pickup != undefined ? order.pickup.state : "")}}
            <b>Mob:</b>
            {{(order.pickup != undefined ? order.pickup.mobile : "")}}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
// import VueBarcode from "vue-barcode";
// var JsBarcode = require('jsbarcode');
import BarcodeGenerator from "./BarcodeGenerator.vue";

export default {
  name: "shiplabel",
  mounted() {
    console.log("generating label for ", this.order.order_number);
    // JsBarcode(".barcodex", this.order.order_number,{format: "CODE128"});
  },
  components: {
    // barcode: VueBarcode,
    BarcodeGenerator,
  },
  props: {
    hideReturn: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: "https://example.com",
      size: 300,
    };
  },
};
</script>




<style scoped>
body {
  background: rgb(204, 204, 204);
}
.a4paper {
  background: white;
  display: block;
  margin: auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
  width: 21cm;
  height: 29.7cm;
  /* border-style: solid; */
  padding: 20px;
}
.hilight {
  border: 1px solid rgba(86, 61, 124, 0.2);
  background: rgba(86, 61, 124, 0.15);
}
.borderdiv {
  border: 1px solid rgb(8, 8, 8);
}
.hilight2 {
  border: 1px solid rgba(86, 61, 124, 0.2);
  background: rgba(245, 10, 182, 0.15);
}
table,
th,
td {
  border: 1px solid black;
}

table {
  border-collapse: collapse;
  width: 450px;
}
@media print {
  #controls {
    margin: 0;
    display: none;
  }
  .a4paper {
    box-shadow: none;
    padding: 0px;
  }
}
</style>
