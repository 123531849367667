<template>
  <q-page class="q-pb-md">
    <div class="text-h4 row text-weight-bold q-ma-lg q-gutter-md"></div>
    <!-- main contenet -->
    <div class="row card q-ma-lg justify-left q-pa-md q-mb-md" style="width:95%;margin:auto">
      <div class="row">
        <q-btn unelevated dense style="width:150px" @click="openWarehouseDialog(null)" class="q-ma-md" color="primary" label="new drop location" no-caps />
      </div>

      <!-- Table  -->
      <div style="width:100%" class="q-mt-md scroll-x">
        <!-- Table -->
        <table class="styled-table">
          <thead>
            <tr>
              <!-- <th>Created At</th> -->
              <th>Drop Location name</th>
              <th>name</th>
              <th>address_line1</th>
              <th>address_line2</th>
              <th>landmark</th>
              <th>mobile</th>
              <th>state</th>
              <th>city</th>
              <th>pincode</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.id">
              <!-- <td>{{new Date(item.created_at).toLocaleString()}}</td> -->
              <td>{{item.drop_name}}</td>
              <td>{{item.name}}</td>
              <td>{{item.address_line1 || ""}}</td>
              <td>{{item.address_line2 || "" }}</td>
              <td>{{item.landmark || ""}}</td>
              <td>{{item.mobile || ""}}</td>
              <td>{{item.state || ""}}</td>
              <td>{{item.city || ""}}</td>
              <td>{{item.pincode || ""}}</td>
              <td >
                <div class="row">
                <q-btn v-if="isDialog" unelevated dense @click="$emit('myevnet',item)" class="q-mr-md q-pl-md q-pr-md" color="orange" label="Select" no-caps />
                <q-btn unelevated dense @click="openWarehouseDialog(item)" class=" q-pl-md q-pr-md" color="primary" label="Modify" no-caps />
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="10" class="text-center">
                <q-btn-group rounded>
                  <q-btn no-caps v-if="pageno>0" @click="previousPage()" color="green" rounded icon="chevron_left" label="Previous" />
                  <q-btn no-caps v-if="!lastpage" @click="nextPage()" color="green" rounded icon-right="navigate_next" label="Next" />
                </q-btn-group>
              </td>
            </tr>
          </tfoot>
        </table>
        <!-- Table End -->
      </div>
    </div>

    <!-- new Wahre house dialog -->
    <!-- Dialog -->
    <q-dialog v-model="settingDialog">
      <q-card style="width: 700px; max-width: 80vw;">
        <q-toolbar>
          <q-avatar icon="settings" font-size="30px"></q-avatar>

          <q-toolbar-title>
            <span class="text-weight-bold">Drop Location</span> Settings
          </q-toolbar-title>

          <q-btn flat round dense icon="close" v-close-popup />
        </q-toolbar>

        <!-- card settings -->
        <q-card-section class="row justify-center">
          <!-- Main Div -->
          <div class="col-12">
            <span class="q-mb-md">
              Drop Location Name
              <span class="text-red">*</span>
            </span>
            <q-input v-model="formData.drop_name" outlined dense />
          </div>
          <div class="row" style="width:100%">
            <div class="q-ma-sm col">
              <span class="q-mb-md">
                Name
                <span class="text-red">*</span>
              </span>
              <q-input v-model="formData.name" outlined dense />
            </div>
            <div class="q-ma-sm col">
              <span class="q-mb-md">
                Mobile
                <span class="text-red">*</span>
              </span>
              <q-input v-model="formData.mobile" outlined dense />
            </div>
          </div>
          <div class="row" style="width:100%">
            <div class="q-ma-sm col">
              <span class="q-mb-md">
                Address Line 1
                <span class="text-red">*</span>
              </span>
              <q-input v-model="formData.address_line1" type="textarea" outlined dense />
            </div>
            <div class="q-ma-sm col">
              <span class="q-mb-md">Address Line 2</span>
              <q-input v-model="formData.address_line2" type="textarea" outlined dense />
            </div>
          </div>
          <div class="row" style="width:100%">
            <div class="q-ma-sm col-12 col-md-6">
              <span class="q-mb-md">landmark</span>
              <q-input v-model="formData.landmark" outlined dense />
            </div>
          </div>
          <div class="row" style="width:100%">
            <div class="q-ma-sm col-12 col-md">
              <span class="q-mb-md">
                Pincode
                <span class="text-red">*</span>
              </span>
              <q-input @blur="api_pincodeInfo()" v-model="formData.pincode" outlined dense />
            </div>
            <div class="q-ma-sm col-md">
              <span class="q-mb-md">
                State
                <span class="text-red">*</span>
              </span>
              <q-input v-model="formData.state" outlined dense />
            </div>
            <div class="q-ma-sm col-md">
              <span class="q-mb-md">
                City
                <span class="text-red">*</span>
              </span>
              <q-input v-model="formData.city" outlined dense />
            </div>
            
          </div>

          <!-- Main Div End -->
        </q-card-section>
        <q-separator />
        <q-card-actions align="right">
          <q-btn v-if="formData.id != undefined" color="red" @click="api_deletewarehouse(formData)">Delete</q-btn>
          <q-btn @click="saveData()" flat>Save</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- new wharehoi se end -->

    <loader v-model="loading"></loader>
  </q-page>
</template>


<script>
import useVuelidate from "@vuelidate/core";
import { required,integer,  minLength, maxLength } from "@vuelidate/validators";

import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],

  name: "MydropLocation",
  components: {
    loader,
  },
  props:{
    isDialog:{
      type: Boolean,
      default: false
    }
  },
  setup() {
    return { v$: useVuelidate() };
  },
  mounted() {
    this.api_loadwarehouse();
  },
  data() {
    return {
      settingDialog: false,
      lastpage: false,
      pageno: 0,
      walletBalance: 0,
      rechargeAmount: "",
      data: [],
      formData: {
        drop_name: "",
        name: "",
        mobile: "",
        address_line1: "",
        address_line2: "",
        landmark: "",
        state: "",
        city: "",
        pincode: "",
      },
    };
  },
  validations() {
    return {
      formData: {
        drop_name: { required },
        name: { required },
        mobile: {
          required,
          integer,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        address_line1: { required },
        address_line2: "",
        landmark: "",
        state: { required },
        city: { required },
        pincode: {
          required,
          integer,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
      },
    };
  },
  methods: {
    nextPage() {
      this.pageno++;
      this.api_loadLedger();
    },
    previousPage() {
      if (this.pageno > 0) {
        this.pageno--;
        this.api_loadLedger();
      }
    },
    openWarehouseDialog(item) {
      if (item != null) {
        this.formData = item;
      } else {
        this.formData = {}; //empty the form
      }
      this.settingDialog = true;
    },
    saveData() {
      this.v$.$validate(); // checks all inputs
      if (this.v$.formData.$invalid) {
        this.showError(
          " " +
            this.v$.formData.$errors[0].$property +
            " " +
            this.v$.formData.$errors[0].$message
        );
      } else {
        this.api_savewarehouse();
      }
    },
    api_savewarehouse() {
      this.loading = true;
      axios
        .post(ULRrepo.DROP_LOCATION_SAVE, this.formData)
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess(response.data.message);
            this.api_loadwarehouse();
            this.settingDialog = false;
            this.formData = {
              drop_name: "",
              name: "",
              mobile: "",
              address_line1: "",
              address_line2: "",
              landmark: "",
              state: "",
              city: "",
              pincode: "",
            };
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
          this.settingDialog = false;
        });
    },
    api_deletewarehouse(item) {
      this.loading = true;
      axios
        .post(ULRrepo.DROP_LOCATION_DELETE, item)
        .then((response) => {
          if (response.status == 200) {
            this.showSuccess(response.data.message);
            this.api_loadwarehouse();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
          this.settingDialog = false;
        });
    },
     api_pincodeInfo() {
      
      this.loading = true;
      axios
        .post(ULRrepo.GENERIC_PINCODEINFO + this.formData.pincode, {})
        .then((response) => {
          if (response.status == 200) {
            //this.showSuccess(response.data.message);
            if (response.data.length > 0) {
              var pininfo = response.data[0];

              this.formData.state = pininfo.state;
              this.formData.city = pininfo.city;
            } else {
              this.showError("Invalid Pincode");
            }
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          console.warn("");
          this.loading = false;
        });
    },
    api_loadwarehouse() {
      var queryParam = {
        drop_location: {
          page: this.pageno,
          _desc: "id",
          _filters: {},
        },
      };
      this.loading = true;
      axios
        .post(ULRrepo.QUERYC, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data = response.data.data;

            //calucate last page
            var totalRecords = response.data.totalRecords;
            var totalPages = Math.round(totalRecords / 25);
            if (this.pageno >= totalPages) {
              this.lastpage = true;
            } else {
              this.lastpage = false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.carddesign {
  max-width: 500px;
  border-radius: 10px;
}
</style>