<!-- eslint-disable vue/no-mutating-props -->

<template>
  <q-card>
    <q-item>
      <q-item-section>
        <q-item-label class="text-h6">{{schema.title}}</q-item-label>
        <q-item-label caption>{{schema.description}}</q-item-label>
      </q-item-section>
    </q-item>
 <q-card-section class="q-pt-xs">
    <table class="paleBlueRows">
      <thead>
        <tr>
          <th>head1</th>
          <th>head2</th>
          <th>head3</th>
          <th>head4</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>cell1_1</td>
          <td>cell2_1</td>
          <td>cell3_1</td>
          <td>cell4_1</td>
        </tr>
        <tr>
          <td>cell1_2</td>
          <td>cell2_2</td>
          <td>cell3_2</td>
          <td>cell4_2</td>
        </tr>
        <tr>
          <td>cell1_3</td>
          <td>cell2_3</td>
          <td>cell3_3</td>
          <td>cell4_3</td>
        </tr>
        <tr>
          <td>cell1_4</td>
          <td>cell2_4</td>
          <td>cell3_4</td>
          <td>cell4_4</td>
        </tr>
        <tr>
          <td>cell1_5</td>
          <td>cell2_5</td>
          <td>cell3_5</td>
          <td>cell4_5</td>
        </tr>
      </tbody>
    </table>
 </q-card-section>

  </q-card>
</template>

<script>

import vcomponent from  './vComponents.js'


export default {
    mixins: [vcomponent],

  name: "Vradiogrid",
  props: {
    schema: Object,
  },
  components: {},
  data() {
    return {
      feildValue:"",
      step: 1,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["Standard", "Express"],
      shape: "line",
      standard: 2,
      options: ["Google", "Facebook", "Twitter", "Apple", "Oracle"],
      columns: [
        {
          name: "name",
          required: true,
          label: "Dessert (100g serving)",
          align: "left",
          field: (row) => row.name,
          format: (val) => `${val}`,
          sortable: true,
        },
        {
          name: "calories",
          align: "center",
          label: "Calories",
          field: "calories",
          sortable: true,
        },
        { name: "fat", label: "Fat (g)", field: "fat", sortable: true },
        { name: "carbs", label: "Carbs (g)", field: "carbs" },
        { name: "protein", label: "Protein (g)", field: "protein" },
        { name: "sodium", label: "Sodium (mg)", field: "sodium" },
        {
          name: "calcium",
          label: "Calcium (%)",
          field: "calcium",
          sortable: true,
          sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),
        },
        {
          name: "iron",
          label: "Iron (%)",
          field: "iron",
          sortable: true,
          sort: (a, b) => parseInt(a, 10) - parseInt(b, 10),
        },
      ],
      rows: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          sodium: 87,
          calcium: "14%",
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          sodium: 129,
          calcium: "8%",
          iron: "1%",
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          sodium: 337,
          calcium: "6%",
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          sodium: 413,
          calcium: "3%",
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          sodium: 327,
          calcium: "7%",
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          sodium: 50,
          calcium: "0%",
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          sodium: 38,
          calcium: "0%",
          iron: "2%",
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          sodium: 562,
          calcium: "0%",
          iron: "45%",
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          sodium: 326,
          calcium: "2%",
          iron: "22%",
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          sodium: 54,
          calcium: "12%",
          iron: "6%",
        },
      ],
    };
  },
};
</script>

<style lang="css" scoped>
table.paleBlueRows {
  font-family: "Comic Sans MS", cursive, sans-serif;
  border: 0px solid #000000;
  width: 100%;
  height: 200px;
  text-align: center;
  border-collapse: collapse;
}
table.paleBlueRows td,
table.paleBlueRows th {
  border: 1px solid #ffffff;
  padding: 3px 2px;
}
table.paleBlueRows tbody td {
  font-size: 13px;
}
table.paleBlueRows tr:nth-child(even) {
  background: #d0e4f5;
}
table.paleBlueRows thead {
  background: #0b6fa4;
  border-bottom: 5px solid #ffffff;
}
table.paleBlueRows thead th {
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  border-left: 2px solid #ffffff;
}
table.paleBlueRows thead th:first-child {
  border-left: none;
}

table.paleBlueRows tfoot td {
  font-size: 14px;
}
</style>
