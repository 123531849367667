/* eslint-disable */


import * as urlrepo from "@/urlrepo.js";
const axios = require("axios").default;

export default{



    methods: {
      showSuccess(msg) {
        this.$q.notify(
            {
                group: false,
                progress: true,
                position: "top",
                type: "positive",
                message: msg,
            },
            3000
        );
    },
    showError(msg) {
        this.$q.notify(
            {
                group: false,
                progress: true,
                position: "top",
                type: "negative",
                message: msg,
            },
            3000
        );
    },

        //init razorpay
        api_initRaZorPay(rechargeAmount){

            var request = {
                amount: rechargeAmount,
            }           
            axios.post(urlrepo.RAZORPAY_INIT, request)
            .then(response => {
             if (response.status == 200) {
              console.warn(response.data);
               this.api_processRazorPay(response.data);
            }
            })
            .catch(error => {
            console.error(error);
            //mix in value
            this.showError(error);
            })
            .then(() => {
            console.warn('');
            });
        },
        //trigger razorpay
        api_processRazorPay(payvalue) {

            var options = {
              key: payvalue.key, // Enter the Key ID generated from the Dashboard
              amount: payvalue.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
              currency: "INR",
              name: "Shippinwala",
              description: "Logistics Simplified",
              image: "https://shippingwala.in/wp-content/themes/SHIPPINGWALA/assets/img/gallery/logo.png",
              order_id: payvalue.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
              handler:  (response) => {
                // alert(response.razorpay_payment_id);
                // alert(response.razorpay_order_id);
                // alert(response.razorpay_signature);
              //recharge done notify the server
              this.api_RechargeDone(response);
              },
              prefill: {
                name: payvalue.uname,
                email: payvalue.uemail,
                contact: payvalue.umob,
              },
              notes: {
              },
              theme: {
                color: "#3399cc",
              },
            };
            var rzp1 = new Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              
              // alert(response.error.code);
              // alert(response.error.description);
              // alert(response.error.source);
              // alert(response.error.step);
              // alert(response.error.reason);
              // alert(response.error.metadata.order_id);
              // alert(response.error.metadata.payment_id);
              this.api_RechargeDone(response);
              //rzp1.close();
               this.componentValue = false;
              this.updateInput();
              //reset formModel to inital value
              this.resetState();     
            });
            rzp1.open();
            //e.preventDefault();
          },
        //recharge done notify the server
        api_RechargeDone(payload){
          
          axios.post(urlrepo.RAZORPAY_DONE, payload)
          .then(response => {
           if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
          }
          })
          .catch(error => {
          console.error(error);
          this.showError(error.response.data.message);

          })
          .then(() => {
          console.warn('');
          });
        
        }
          
        

    }



}