<template>
  <!-- Shippmet Speed -->
  <div class="card q-ma-md col-12 col-md-12 q-pb-md ">
    <div class="text-h6 col-12 q-ml-md">Shipment Speed Price</div>

    <div class="row">
      <div class="row q-ml-md q-mr-md" style=" overflow-x: scroll;width: 100%;">
        <!-- Add new Data -->
        <table class="styled-table" style="border: black; border-style: groove;">
          <thead>
            <tr>
              <th>Category</th>
              <th>Shipping Speed</th>
              <th>price_type</th>
              <th>price</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{formData.category}}</td>
              <td>
                <select v-model="formData.shipping_speed">
                  <option disabled value>Please select one</option>
                  <option>standard</option>
                  <option>express</option>
                </select>
              </td>
              <td>
                <select v-model="formData.price_type">
                  <option disabled value>Please select one</option>
                  <option>flat_rate</option>
                  <option>commission_percent</option>
                  <option>commission_fixed</option>
                </select>
              </td>
              <td>
                <input v-model="formData.price" placeholder="enter the price" />
              </td>
              <td>
                <button @click="api_addData()" type="button">Add Price</button>
              </td>
            </tr>
            <!-- and so on... -->
          </tbody>
        </table>
        <!-- New Data end -->

        <table class="styled-table">
          <thead>
            <tr>
              <th>Category</th>
              <th>Shipping Speed</th>
              <th>price_type</th>
              <th>price</th>
              <th>action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.id">
              <td>{{item.category}}</td>
              <td>{{item.shipping_speed}}</td>
              <td>{{item.price_type}}</td>
              <td>
                <input v-model="item.price" placeholder="enter the price" />
              </td>
              <td>
                <button class="q-mr-md" @click="api_updateData(item)" type="button">Update</button>
                <button @click="api_deleteData(item)" class="bg-red" type="button">Delete</button>
              </td>
            </tr>

            <!-- and so on... -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <!-- Shippmet Speed -->
  <loader v-model="loading"></loader>
</template>


<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "PricingShippingSpeed",
  components: {
    loader,
  },
  props: {
    selectedCategory: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.api_loadData();
  },
  watch: {
    selectedCategory: function () {
      this.formData.category = this.selectedCategory;
      this.api_loadData();
    },
  },
  data() {
    return {
      formData: {
        category: this.selectedCategory,
        shipping_speed: "standard",
        price_type: "flat_rate",
        price: 10,
      },
      data: [],
    };
  },
  methods: {
    api_addData() {
      this.loading = true;
      axios
        .post(ULRrepo.PRICING_ADD_SHIPPING, this.formData)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);
            this.api_loadData();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
    api_updateData(item) {
      this.loading = true;
      axios
        .post(ULRrepo.PRICING_UPDATE_SHIPPING, item)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);

            this.api_loadData();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
    api_deleteData(item) {
      this.loading = true;
      axios
        .post(ULRrepo.PRICING_DELETE_SHIPPING, item)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.showSuccess(response.data.message);

            this.api_loadData();
          }
        })
        .catch((error) => {
          console.error(error);
          this.showError(error.response.data.message);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
    api_loadData() {
      var queryParam = {
        pricing_shipping_speed: {
          page: 0,
          _desc: "id",
          _filters: {
            category: ` = '${this.formData.category}'`,
          },
        },
      };
      this.loading = true;
      axios
        .post(ULRrepo.QUERY, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data = response.data.data;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-style: dotted;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
  padding: 5px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>