<template>
  <q-page class="q-pb-md">
    <div class="row scroll-x" style="width:100%;margin:auto;">
      <div class="inputs text-h6 col-12 scroll-x">COD Remmitance</div>

      <!-- Table --> 
      <table class="styled-table">
        <thead>
          <tr>
            <th>Created At</th>
            <th>Remmitance trasncation id</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Status</th>
            <th>View Order list</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>{{new Date(item.created_at).toLocaleString()}}</td>
            <td>{{item.txn_id}}</td>
            <td>{{item.description || ""}}</td>
            <td>{{item.amount || "" }}</td>
            <td>{{item.status || ""}}</td>
            <td>
              <q-btn style="width:150px" color="green" @click="api_downloadList(item.txn_id)" icon="view_headline" label="Order List" no-caps unelevated />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="10" class="text-center">
              <q-btn-group rounded>
                <q-btn no-caps v-if="pageno>0" @click="previousPage()" color="green" rounded icon="chevron_left" label="Previous" />
                <q-btn no-caps v-if="!lastpage" @click="nextPage()" color="green" rounded icon-right="navigate_next" label="Next" />
              </q-btn-group>
            </td>
          </tr>
        </tfoot>
      </table>
      <!-- Table End -->
    </div>
    <loader v-model="loading"></loader>
  </q-page>
</template>


<script>
import globalmix from "@/globalmix.js";
import * as ULRrepo from "@/urlrepo.js";
const axios = require("axios").default;
import loader from "@/components/loaders/LoadingDialog.vue";

export default {
  mixins: [globalmix],
  name: "UserSetting",
  components: {
    loader,
  },
  mounted() {
    this.api_loadOnline();
  },
  data() {
    return {
      lastpage: false,
      pageno: 0,
      data: [],
      orderList: [],
    };
  },
  methods: {
    nextPage() {
      this.pageno++;
      this.api_loadOnline();
    },
    previousPage() {
      if (this.pageno > 0) {
        this.pageno--;
        this.api_loadOnline();
      }
    },
    btn_rechangeNow() {
      this.api_initRaZorPay(this.rechargeAmount);
    },
    api_loadOnline() {
      var queryParam = {
        orders_v3_cod_remmitance: {
          page: this.pageno,
          _desc: "id",
          _filters: {},
        },
      };
      this.loading = true;
      axios
        .post(ULRrepo.QUERYC, queryParam)
        .then((response) => {
          if (response.status == 200) {
            console.warn(response.data);
            this.data = response.data.data;

            //calucate last page
            var totalRecords = response.data.totalRecords;
            var totalPages = Math.round(totalRecords / 25);
            if (this.pageno >= totalPages) {
              this.lastpage = true;
            } else {
              this.lastpage = false;
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .then(() => {
          this.loading = false;
          console.warn("");
        });
    },
    api_downloadList(selected) {
      var urlx = ULRrepo.COD_DOWNLOAD + "?txnid=" + selected;
      // window.open(url);
      this.loading=true;
      axios({
        url: urlx, //your url
        method: "POST",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "shippingdata.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.loading=false;
      });
    },


    
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  margin: 10px;
  width: 300px;
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>