<template>
  <!-- Presistant progress dialog -->
  <q-dialog :value="value" v-on:input="$emit('input', $event )" @hide="dialogHidden" persistent>
    <q-card>
      <q-img :src="getLoaderImage()" basic></q-img>
      <q-card-section class="row items-center q-pa-sm">
        <span class="q-ml-sm">{{ message }}</span>
      </q-card-section>
      <q-linear-progress indeterminate color="green" class="q-mt-sm" />
      <!-- <q-card-actions align="right"> </q-card-actions> -->
    </q-card>
  </q-dialog>
  <!-- end -->
</template>

<script>
export default {
  name: "LoadingDialog",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: "Please wait while your request being processed."
    },
    loader:{
      type: String,
      default: "pickupTruck"
    }
  },
  data() {
    return {
      imageSrc:
        "https://media1.tenor.com/images/cb936926d59302a4944281af827f8992/tenor.gif?itemid=12479903"
    };
  },
  methods: {
    dialogHidden(val) {
      // console.log("====> "+ JSON.stringify( val));
      //this.$emit('input',false);
      console.log("====> "+ val);
    },
    deleteButtonClicked() {
      //this.$emit('input', this.local_showDialog);
      this.$emit("deleted");
    },
    getLoaderImage(){

          
          switch(this.loader){

              case "pickupTruck":
                return require('../../assets/pickuptruck.gif');
          }

    }
  }
};
</script>
