<template>
    
  <div class="bg-indigo-11 col q-ma-lg q-pa-lg">
    <div class="text-h3 text-weight-bold title">
      Welcome, {{firstName}} {{lastName}}. 👋
    </div>
    <div class="text-h5 subtitle">
      Here is what’s happening with your Account today:
    </div>
  </div>


</template>




<script>
export default {
  name: 'welcome',
  mounted(){
      var user = JSON.parse(localStorage.getItem('user'));
      this.firstName = user.first_name;
      this.lastName = user.last_name;
  },
  data(){
    return {
      firstName:'',
      lastName:'',
    }
  }
}
</script>

<style lang="css" scoped>

@media screen and (max-width: 600px){  

  .title {
      font-size: 1.4rem;
  }
  .subtitle {
      font-size: 1rem;
      line-height: 1rem;
  }

}

</style>