<!-- eslint-disable vue/no-mutating-props -->

<template>
 
       <q-card  :class="showError ? 'bg-red-4':''">
        <q-item>
                 <q-item-section>
            <q-item-label>{{schema.title}} <span class="text-red">{{schema.required ? '*' : ''}}</span></q-item-label>
            <q-item-label caption>{{schema.description}}</q-item-label>
          </q-item-section>
        </q-item>
        <q-card-section class="q-pt-xs">
          <q-slider label-always switch-label-side v-model="result[schema.key]" :min="0" :max="50" color="green" />
        </q-card-section>
      </q-card>
</template>

<script>

import vcomponent from  './vComponents.js'


export default {
    mixins: [vcomponent],

  name: "Vslider",
   props: {
     schema:Object
   },
  components: {},
  data() {
    return {
      feildValue:0,
      step: 1,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["Standard", "Express"],
      shape: "line",
      standard: 2,
      options: [
        'Google', 'Facebook', 'Twitter', 'Apple', 'Oracle'
      ],
    };
  },
};
</script>
