<template>
  <q-layout view="hHh lpR fFf" class="bg-grey-1">
    <q-header elevated class="bg-white text-grey-8" height-hint="64">
      <q-toolbar class="GPL__toolbar" style="height: 64px">
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="menu" class="q-mx-md" />

        <q-toolbar-title v-if="$q.screen.gt.sm" shrink class="row items-center no-wrap">
          <img class="q-pl-md" style="width: 250px; height:40px;" src="@/assets/logo_banner.png" />
          <!-- <span class="q-ml-sm">Photos</span> -->
        </q-toolbar-title>

        <q-space />

        <q-input class="GPL__toolbar-input" @keyup.enter="btn_search()" dense standout="bg-primary" v-model="search" placeholder="Search order or tracking number">
          <template v-slot:prepend>
            <q-icon v-if="search === ''" name="search" />
            <q-icon v-else name="clear" class="cursor-pointer" @click="search = ''" />
          </template>
        </q-input>

        <!-- <q-btn v-if="$q.screen.gt.xs" flat dense no-wrap color="primary" icon="add" no-caps label="Create" class="q-ml-sm q-px-md">
          <q-menu anchor="top end" self="top end">
            <q-list class="text-grey-8" style="min-width: 100px">
              <q-item aria-hidden="true">
                <q-item-section class="text-uppercase text-grey-7" style="font-size: 0.7rem">Create New</q-item-section>
              </q-item>
              <q-item v-for="menu in createMenu" :key="menu.text" clickable v-close-popup aria-hidden="true">
                <q-item-section avatar>
                  <q-icon :name="menu.icon" />
                </q-item-section>
                <q-item-section>{{ menu.text }}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>-->

        <!-- <q-btn v-if="$q.screen.gt.xs" flat dense no-wrap color="primary" icon="cloud_upload" no-caps label="Upload" class="q-ml-sm q-px-md" /> -->

        <q-space />
        <q-space />
        <q-space />
        <q-space />

        <div class="q-gutter-sm row items-center no-wrap">
          <!-- <q-btn round dense flat color="text-grey-7" icon="apps">
            <q-tooltip>Google Apps</q-tooltip>
          </q-btn> -->
          <!-- <q-btn round dense flat color="grey-8" icon="notifications">
            <q-badge color="red" text-color="white" floating>2</q-badge>
            <q-tooltip>Notifications</q-tooltip>
          </q-btn> -->
          <q-btn  @click="logout()" round dense flat color="grey-8" icon="power_settings_new">
            <q-tooltip>Logout</q-tooltip>
          </q-btn>
          <!-- <q-btn round flat>
            <q-avatar size="26px">
              <img src="https://cdn.quasar.dev/img/boy-avatar.png">
            </q-avatar>
            <q-tooltip>Account</q-tooltip>
          </q-btn>-->
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" bordered overlay>
      <sidebar></sidebar>
    </q-drawer>

    <q-page-container class="bg-indigo-1">
      <router-view></router-view>
    </q-page-container>
  </q-layout>
</template>

<script>
// import { ref } from 'vue'
import sidebar from "@/components/sidebar/Sidebar.vue";

export default {
  name: "GooglePhotosLayout",
  components: {
    sidebar,
  },
  data() {
    return {
      leftDrawerOpen: false,
      search:"",
    };
  },
  methods: {
    logout() {
      let text = "Do you want to logout?";
      if (confirm(text) == true) {
        //logout from the system
        localStorage.clear();
        this.$router.push("/");
      }
    },
    btn_search(){
        if(this.search!="")
        {
           this.$router.push({
            name: 'orderlist',
             query: {odr:this.search},
            });
          this.search="";
        }else{
        alert("Please enter order or tracking number");
        }

    }
  },
};
</script>

<style lang="sass">
.GPL

  &__toolbar
    height: 64px

  &__toolbar-input
    width: 35%

  &__drawer-item
    line-height: 24px
    border-radius: 0 24px 24px 0
    margin-right: 12px

    .q-item__section--avatar
      padding-left: 12px
      .q-icon
        color: #5f6368

    .q-item__label:not(.q-item__label--caption)
      color: #3c4043
      letter-spacing: .01785714em
      font-size: .875rem
      font-weight: 500
      line-height: 1.25rem

    &--storage
      border-radius: 0
      margin-right: 0
      padding-top: 24px
      padding-bottom: 24px

  &__side-btn
    &__label
      font-size: 12px
      line-height: 24px
      letter-spacing: .01785714em
      font-weight: 500

  @media (min-width: 1024px)
    &__page-container
      padding-left: 94px
</style>