<!-- eslint-disable vue/no-mutating-props -->

<template>
  <q-card :class="showError ? 'bg-red-4':''">
    <q-item>
      <q-item-section>
        <q-item-label>{{schema.title}} <span class="text-red">{{schema.required ? '*' : ''}}</span></q-item-label>
        <q-item-label caption>{{schema.description}}</q-item-label>
      </q-item-section>
    </q-item>
    <q-card-section class="q-pt-xs">
      <div class="q-gutter-xs row">
        <template v-for="item in schema.options" :key="item">
          <q-checkbox class="col-12" v-model="result[schema.key]" :val="item" :label="item" color="teal" />
        </template>
        <!-- Others options --> 
       <!-- <q-input  style="width:100%" label="others" dense v-model="result[schema.key]" outlined /> -->
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import vcomponent from  './vComponents.js'

export default {
  mixins: [vcomponent],
  name: "Vcheck",
  props: {
    schema: Object,
    result: Object,
  },
  created() {
    this.initialResultValue();
  },
  components: {},
  data() {
    return {
      feildValue: [],
      shape: "line",
    };
  },
  methods: {
   
  },
};
</script>
