<template>
  <q-page class="q-pb-md">
    <div class="text-subtitle1 text-weight-bold q-ma-lg">Deliver Custom SMS to your customer</div>
    <div class="text-h4 row text-weight-bold q-ma-lg q-gutter-md"></div>
    <!-- main card -->
    <div class="q-pa-md row q-gutter-md justify-center" style="width:100%;margin:auto">
   

        <q-card class="col-5">
        <q-item>
          <q-item-section avatar>
            <q-checkbox v-model="right" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-bold">Booked</q-item-label>
            <q-item-label caption>sms when order is added into the portal</q-item-label>
          </q-item-section>
        </q-item>
        <q-input class="q-pl-md q-pr-md q-pb-md" type="textarea" outlined dense />
      </q-card>

      <q-card class="col-5">
        <q-item>
          <q-item-section avatar>
            <q-checkbox v-model="right" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-bold">Assigned</q-item-label>
            <q-item-label caption>sms when order is assigned to courier</q-item-label>
          </q-item-section>
        </q-item>
        <q-input class="q-pl-md q-pr-md q-pb-md" type="textarea" outlined dense />
      </q-card>

       <q-card class="col-5">
        <q-item>
          <q-item-section avatar>
            <q-checkbox v-model="right" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-bold">Pickup done</q-item-label>
            <q-item-label caption>sms when order is picked up</q-item-label>
          </q-item-section>
        </q-item>
        <q-input class="q-pl-md q-pr-md q-pb-md" type="textarea"  outlined dense />
      </q-card>

       <q-card class="col-5">
        <q-item>
          <q-item-section avatar>
            <q-checkbox v-model="right" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-bold">In Transist</q-item-label>
            <q-item-label caption>Order is on the way</q-item-label>
          </q-item-section>
        </q-item>
        <q-input class="q-pl-md q-pr-md q-pb-md" type="textarea"  outlined dense />
      </q-card>
      
        <q-card class="col-5">
        <q-item>
          <q-item-section avatar>
            <q-checkbox v-model="right" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-bold">Out for Delivery</q-item-label>
            <q-item-label caption>Order is on the way</q-item-label>
          </q-item-section>
        </q-item>
        <q-input class="q-pl-md q-pr-md q-pb-md" type="textarea"  outlined dense />
      </q-card>

       <q-card class="col-5">
        <q-item>
          <q-item-section avatar>
            <q-checkbox v-model="right" />
          </q-item-section>

          <q-item-section>
            <q-item-label class="text-bold">Delivered</q-item-label>
            <q-item-label caption>sms when order is delivered</q-item-label>
          </q-item-section>
        </q-item>
        <q-input class="q-pl-md q-pr-md q-pb-md" type="textarea"  outlined dense />
      </q-card>    


    </div>
  </q-page>
</template>


<script>
export default {
  name: "SmsBranding",
  components: {},
  data() {
    return {
      step: 1,
      shipmentSpeed: ["Standard", "Express"],
      paymentMethod: ["Standard", "Express"],
    };
  },
};
</script>

<style lang="css" scoped>
.inputs {
  /*max-width: 500px;*/
  padding: 10px;
  /* width: 300px; */
}

.card {
  background: white;
  /* box-shadow: 0 4px 8px 0 rgb(232 234 246); */
  box-shadow: 0 25px 50px rgb(8 21 66 / 6%);
  border-radius: 5px;
  border-color: grey;
  /* border-style: double; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.carddesign {
  max-width: 500px;
  border-radius: 10px;
}
</style>